import React from 'react';
import { styled } from '@mui/system';
import SvgIcon from '@mui/material/SvgIcon';



const DescriptionOffLogo = styled(SvgIcon)(({ theme }) => ({
  /* Add your custom styles here */
  width: '28px',
  height:'28px',
  fill: 'currentColor',
  stroke: 'currentColor',

}));



function DescriptionOffIcon(props) {
  return (
    <DescriptionOffLogo>
<svg version="1.1" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
 <defs>
  <mask id="mask-powermask-path-effect3444" maskUnits="userSpaceOnUse">
   <path id="mask-powermask-path-effect3444_box" d="m13.06 13.81h18v22h-18z" fill="#fff"/>
   <path d="m13.47 12.69 19.52 23.4" fill="none" stroke="#000" strokeWidth="4"/>
  </mask>
 </defs>
 <path transform="matrix(1.349 0 0 1.349 -14.06 -17.97)" d="m23.06 16.81h-7v16h12v-11h-5zm3 14h-8v-2h8zm0-6v2h-8v-2z" mask="url(#mask-powermask-path-effect3444)" opacity=".3"/>
 <path transform="matrix(1.349 0 0 1.349 -14.06 -17.97)"
 d="m18.06 28.81h8v2h-8zm0-4h8v2h-8zm6-10h-8c-1.1 0-2 0.9-2 2v16c0 1.1 0.89 2 1.99 2h12.01c1.1 0 2-0.9 2-2v-12zm4 18h-12v-16h7v5h5z"
 mask="url(#mask-powermask-path-effect3444)"
 strokeWidth="0.25"/>
 <path d="m4.146 2.175 23.71 28.18" fill="none" stroke="currentColor" strokeWidth="2.8"/>
</svg>



    </DescriptionOffLogo>
  );
}



export default DescriptionOffIcon;


/*


      <svg width="100%" height="100%" version="1.1" viewBox="-6 -1 37 37" xmlns="http://www.w3.org/2000/svg">
      <path transform="translate(-11.85 -13.33)" d="m23.06 16.81h-7v16h12v-11h-5zm3 14h-8v-2h8zm0-6v2h-8v-2z" mask="url(#mask-powermask-path-effect3444)" opacity=".3" />
        <defs>
          <mask id="mask-powermask-path-effect3444" maskUnits="userSpaceOnUse">
            <path id="mask-powermask-path-effect3444_box" d="m13.06 13.81h18v22h-18z" fill="#fff" />
            <path d="m13.47 12.69 19.52 23.4" fill="#fff" stroke="#000" strokeWidth="5" />
          </mask>

        </defs>

        <path transform="translate(-11.85 -13.33)" d="m18.06 28.81h8v2h-8zm0-4h8v2h-8zm6-10h-8c-1.1 0-2 0.9-2 2v16c0 1.1 0.89 2 1.99 2h12.01c1.1 0 2-0.9 2-2v-12zm4 18h-12v-16h7v5h5z" mask="url(#mask-powermask-path-effect3444)" />
        <path d="m0.7679 0.6406 19.52 23.4" fill="none" stroke="currentColor" strokeWidth="3" />
      </svg>



*/