
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import config from '../config';

export const useCreatePatientMutation = ({setFormModalOpen, setsnackbarStatus}) => {

    return useMutation({
        mutationFn: ({ patientForm }) => {


            return axios.post(
                `${config.API_URL}/data/createPatient`,
                patientForm,
                {
                    withCredentials: true,
                }
            );
        },
        onSuccess: (data, variables) => {
            setsnackbarStatus({open: true, severity: 'success', message: 'Patient Case Created Successfully'});
            setFormModalOpen(false);
        },
        onError: (error, variables) => {
            const errorMessage = error?.response?.data?.error?.message || 'Failed to Create Patient Case';
            setsnackbarStatus({open: true, severity: 'error', message: errorMessage, duration: 12000});
        }
    });
}
