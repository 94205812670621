import React from 'react';

import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import {
    Box,
    Button,  Divider,
    Typography,
    useTheme
} from '@mui/material';
import LocalHospitalRoundedIcon from '@mui/icons-material/LocalHospitalRounded';
import LocalPharmacyOutlinedIcon from '@mui/icons-material/LocalPharmacyOutlined';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import DentistIcon from '../../img/DentistIcon';
import MedCenterIcon from '../../img/MedCenterIcon';
import DoctorIcon from '../../img/DoctorIcon';
import EditIcon from '@mui/icons-material/Edit';
import { AccordionDetails } from '../StyledComponents/AccordionDetails';
import { AccordionSummary } from '../StyledComponents/AccordionSummary';
import { Accordion } from '../StyledComponents/Accordion';

import TextLanguage from '../data/TextLanguage.json';

import { interpolateColor } from '../../functions/interpolateColor'


export const HospitalAccordion = ({
    lang,
    data,
    expanded, expandAll, selectedValue,
    handleAccordChange,
    accordpanelIDInstant,
    contactGlow,
    setOpenModal, setaccordpanelIDInstant, durationRange, regionalMode,


}) => {


    const theme = useTheme();
    // Disabled debounce on accordion hover
    // const accordionFlyWaitTime = 250;
    //const leaveTimeoutRef = useRef(null);
    //const [isMouseInside, setisMouseInside] = useState(false); // Inside accordion

    const assignAccordColor = (i) => {
        if (showAsContractedHRef) { return '#052306bf' }
        else if (accordpanelIDInstant === i) { return 'rgba(4, 46, 98, 0.33)' }
        else { return 'rgba(5,5,5,0.2)' }
    }

    const handleAccordionEnter = (panel) => (event, newExpanded) => {


        setaccordpanelIDInstant(panel);
        //setaccordpanelID(panel);
        /*
                setisMouseInside(true);
                console.log(isMouseInside);

                        leaveTimeoutRef.current = setTimeout(() => {
                            if (isMouseInside) {
                                setaccordpanelID(panel);

                            };

                        }, accordionFlyWaitTime);
        */

    };

    const handleAccordionLeave = (panel) => (event, newExpanded) => {
        //setisMouseInside(false);
        setaccordpanelIDInstant(-1);
        //clearTimeout(leaveTimeoutRef.current);
    };

    const handleModalOpen = (index) => {
        setOpenModal(index);
    };

    const duration = data?.directionAPI?.routes[0]?.duration;
    const DurationTime = new Date(duration * 1000);
    const parsedDuration = regionalMode ? '' : `${DurationTime?.getUTCHours() ? `${DurationTime?.getUTCHours()}${TextLanguage["HourShortcut"][lang]}` : ''} ${DurationTime?.getUTCMinutes()}${TextLanguage["MinuteShortcut"][lang]}`

    const compareContractName = (selectedValue && selectedValue.Contract && (selectedValue?.Contract?.trim() === data?.Name?.trim() || selectedValue?.Contract?.trim()?.includes(data?.Name?.trim()) || data?.Name?.trim()?.includes(selectedValue?.Contract?.trim()))  )
    const showAsContractedHRef = (selectedValue && selectedValue.Contract && ( !!data?.MedGroup && selectedValue?.Contract?.includes(data?.MedGroup) || compareContractName))

    return (<>
        <Divider variant="middle" className="accordDivide" />
        <Accordion className="accordClass"
            expanded={data.Notes && (expanded === data.index) || ((expandAll && data?.Notes)) || (showAsContractedHRef && data?.Notes)}
            key={data.index + 1}
            onChange={handleAccordChange(data.index)}
            onMouseEnter={handleAccordionEnter(data.index)}
            onMouseLeave={handleAccordionLeave(data.index)}
        >

            <AccordionSummary
                key={data.index + 1}
                sx={{ overflow: 'visible', color: showAsContractedHRef ? 'hsl(131, 96%, 66%)' : 'white' }}
                bgcolor1={assignAccordColor(data.index)}
                accordheight={(accordpanelIDInstant === data.index) ? (48 + 5) : 48}

                bgcolor2={(accordpanelIDInstant === data.index) ? 'rgba(255,115,55,0.25)' : 'rgba(255,115,55,0.05)'}
                expandIcon={<ArrowForwardIosSharpIcon sx={{
                    fontSize: '1.25rem',
                    color: showAsContractedHRef ? (data.Notes ? 'hsl(131, 96%, 66%)' : 'hsl(131, 66%, 20%)') : ((data.Notes) ? 'white' : 'rgba(34, 34, 37, 0.9)')
                }} />}
                aria-controls="panel-content" id={`panel-header${data.index}`}
                className='accordSumClass'>

                <div style={{ display: 'flex', justifyContent: 'center',alignItems:'center',}}>
                    {HospitalIconPicker(data.Type)}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginRight: '6px' }}>

                    <Typography className='accordText' >
                        {data.Name}
                    </Typography>
                    {(accordpanelIDInstant !== data.index) ?
                        <Typography className='accordText' sx={{ color: interpolateColor(duration, durationRange[0], durationRange[1], "hsl(98, 99%, 44%)", "hsl(0, 100%, 44%)") }}>
                            {`${parsedDuration}`}
                        </Typography>
                        : null
                    }
                </div>

                {
                    regionalMode ?
                        (accordpanelIDInstant === data.index) &&
                        <Button disableRipple sx={{ zIndex: 1250 }} onClick={() => handleModalOpen(data.index)}>
                            <EditIcon sx={{ color: "white", height: 25, width: 25 }} />
                        </Button>
                        :
                        ((accordpanelIDInstant === data.index)) &&
                        (<Button disableRipple sx={{ overflow: 'visible', }} onClick={() => handleModalOpen(data.index)}>
                              <PhoneEnabledIcon sx={{
                                    color: "rgba(33,194,27,0.7)",
                                    zIndex: 8,
                                    height:32,
                                    width:27,
                                }} />

                                 <div style={{backgroundColor:'rgba(50,50,50,1)',border:'1px solid rgba(255,255,255,0.25)',
                                    width:255,height:'80%',position:'absolute',zIndex:5,left:'5px',borderRadius:40,boxShadow:'0px 0px 12px 4px hsla(89, 75.90%, 65.90%, 0.35)'}}></div>

                        </Button>)}
            </AccordionSummary>
            <AccordionDetails sx={{p:'0.6rem 2rem', borderTop:'2px solid var(--color-gray-tint)'}} bgcolor={assignAccordColor(data.index)}>
                <Typography sx={{ color: 'var(--color-text)', fontSize:'1rem' }}> {data?.Notes ? data.Notes : ''}  </Typography>
            </AccordionDetails>
        </Accordion>

    </>);
}

function HospitalIconPicker(Type) {
    return Type === 'Hospital' ?
        (<LocalHospitalRoundedIcon style={{ alignSelf: 'center', color: 'red' }} />)
        :
        Type === 'Clinic' ?
            (<LocalPharmacyOutlinedIcon style={{ alignSelf: 'center', color: 'rgba(33,194,27,0.7)' }} />)
            : Type === 'MedCenter' ? (
                <div style={{ alignSelf: 'center', color: 'rgba(255,255,0,0.4)' }}>
                    <MedCenterIcon style={{ alignSelf: 'center', }} />
                </div>
            ) : Type === 'Dentist' ? (
                <div style={{ alignSelf: 'center', color: 'rgba(255,255,255)' }}>
                    <DentistIcon style={{ alignSelf: 'center' }} />
                </div>
            ) : Type === 'Doctor' ? (

                <div style={{ color: 'rgb(125,188,255)' }}>
                    <DoctorIcon />
                </div>
            ) : null;
}

