
// Flame medical in hotel list (Adres:Molla Yusuf Mah. 1476 Sok. A Blok Apt. No:7 A/1 Konyaaltı, Antalya / Telefon:0242 241 00 13)


/*

  !!! Regex is buggy "(Ergin bey, Dr.Cemşit bey:) (0534)" should be "(Ergin bey, Dr.Cemşit bey:)(0534 254 10 09)/(0507 274 50 54)/(0534 254 10 09)"

  To do list:


    1. Data Processing: Fix hotel info list and update (Merge list of contracted hosp with main hosp list--figure out what to do with missing hospitals)
    Go through the Medical sheet in PRO (new types to include: Ambulance+Air/MedSupplies/dentists+polyclinics)
    Daily Reports include different hospitals, missing from either hospital list (especially for less active areas like Cyprus)


    2. Handle Type: {dentist/clinic} with filter buttons so that these show up on both filters (only one clinic with said type for now)

    3. Add a split button for filter, one for filter toggle, the
     other for show only (formats = ['x'])
    6. Make sure the lists don't contain null values + handle exceptions like 'X','M' in locations for traveling doctors
    7. Finish sorting out hospital MedGroup and implement hotel preference + add indv entries for anadolu hospitals
    8. Maybe filter out anadolu group for hotels that aren't signed on with them (find out if that's temp and if it applies to other groups)
    9. Find or design logos for clinic(poly), dent, doctor-clinic and medcenters (possibly merge types if not useful) -----One logo left---Maybe merge clinics-doctors
    12. Fully implement theme, remove forced inlines and css
    13. Add formatting for Phone,Fax etc.


    Questions:
    Clinic International (which hospital? mayday is also called mayday clinic international)
    ZIMNIY PROVAYDER? Uludag Palandoken -- Med-Asist SH ve Eker + Buhara Tip Merkezi Erzurum

    (Caria Lujo Hotel Bodrum À La Carte All Inclusive ile anlasmali gorunuyo, yaninda doktor ofisine gonderme diyo, caria mi yoksa hastane ici doktordan mi bahsedilio (caria ise hosplistden kaldir!)
    Same thing for tepe clinic
    Missing Hospitals (Flame Medical(contract for hosp but no record on our end (is it ignored?)), May Day Marmaris, Letoon, Alaiye MC, Marina Clinic) Missing Doctors (Ismet Yildiz, Unal bilgin, Mustafa Sarisli, Taner karaman{connected to like 6 hospitals})



*/
import { useState, useEffect, useRef, forwardRef } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';


import {
    Box,
    Modal,
    Typography,
    Button,
    TextField,
    ToggleButton,
    MenuItem

} from '@mui/material';
import { CustomSnackbar } from '../components/StyledComponents/CustomSnackbar';

import { matchSorter } from 'match-sorter';

import TextLanguage from '../components/data/TextLanguage.json';
import useLocalStorage from '../hooks/useLocalStorage';



import { useValidateQuery } from '../hooks/useValidateQuery';
import { useHotelQuery } from '../components/OperatorApp/hooks/useHotelQuery';
import { useHospitalQuery } from '../components/OperatorApp/hooks/useHospitalQuery';
import { useRegionQuery } from '../components/OperatorApp/hooks/useRegionQuery';

import { AppBar } from '../components/AppBar';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ContextMenu } from 'primereact/contextmenu';


import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { useUpdateHospitalMutation } from '../components/OperatorApp/hooks/useUpdateHospitalMutation';
import { useUpdateHotelMutation } from '../components/OperatorApp/hooks/useUpdateHotelMutation';
import { useQueryClient } from '@tanstack/react-query';

import { debounce } from 'lodash';

import { styled } from '@mui/material';
import { FormTab } from '../components/StyledComponents/FormTab';
import { FormTabs } from '../components/StyledComponents/FormTabs';
import TextFilter from '../components/StyledComponents/TextFilter';
import { NewPatientButton } from '../components/StyledComponents/NewPatientButton';
import { ModalStyle } from '../components/StyledComponents/ModalStyle';
import { HotelCreateForm } from '../components/DataEditor/components/HotelCreateForm';
import { useRemoveHotelMutation } from '../components/DataEditor/hooks/useRemoveHotelMutation';
import { useRemoveHospitalMutation } from '../components/DataEditor/hooks/useRemoveHospitalMutation';
import { HospitalCreateForm } from '../components/DataEditor/components/HospitalCreateForm';
import { useMapboxKeyQuery } from '../components/OperatorApp/hooks/useMapboxKeyQuery';
import remToPx from '../functions/remToPx';
import { RegionSelectorDialog } from '../components/DataEditor/components/RegionSelectorDialog';

import { FormField } from '../components/StyledComponents/FormField';
import { useUserListQuery } from '../components/OperatorApp/hooks/useUserListQuery';
import { useUpdateUserMutation } from '../components/OperatorApp/hooks/useUpdateUserMutation';
import { UserCreateForm } from '../components/DataEditor/components/UserCreateForm';
import { useRefreshQuery } from '../components/Login/useRefreshQuery';
import { useNavigate } from 'react-router-dom';

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    border: 'none',
    outline: 'none',
    width: '100%',
    maxWidth: '8rem',
    boxShadow: 'inset 0px 0px 8px 0px #000000b5',
    '&.MuiToggleButton-root': {
        fontSize: '1rem',
        padding: '0.25rem 0.5rem',
        color: 'white',
        background: 'rgb(49,80,125)',
        '&.Mui-selected': {
            background: 'rgb(51, 110, 199)',
            color: 'white'
        }
    }
}));


const filteredList = (data, filter, keys) => {
    if (!data) return [];
    return (filter ? matchSorter(data, filter, {
        keys: keys,
        base: 10,
        maxRanking: 10,
        threshold: matchSorter.rankings.CONTAINS
    }) : data)
};

const defaultColumnStyle = (size) => {
    return {
        width: `${size}px`,
        minWidth: `${size}px`,
        maxWidth: `${size}px`,

    }
};


export function DataEditor() {

    const currentPage = 'DataEditor';
    const queryClient = useQueryClient();
    // Map Hooks

    const datatableRef = useRef(null);
    const [dataTableKey, setDataTableKey] = useState(0);

    useEffect(() => {
        // Trigger re-render after component has mounted (fix for virtual scroller bug)
        setDataTableKey(prevKey => prevKey + 1);
    }, []);

    const [lang, setlang] = useLocalStorage('lang', 'trTR');



    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'

    });

    const MapboxAPIKeyQuery = useMapboxKeyQuery();


    // Query Hooks

    const navigate = useNavigate();

    const validateQuery = useValidateQuery();
    const confirmedUserID = validateQuery?.data?.data?.userID;
    const AdminMode = ['Admin'].includes(confirmedUserID);
    const ExpertiseMode = ['Admin', 'Expertise','ExpertisePayment'].includes(confirmedUserID);


    const refreshQuery = useRefreshQuery();


    useEffect(() => {

        if (refreshQuery.isError && refreshQuery.failureCount >= 3 ) {
            console.log('Refresh Token Expired');
            queryClient.clear()
            navigate('/');
        } else if (refreshQuery.isError && refreshQuery.error?.response?.status === 403) {
            console.log('Refresh Token Expired');
            queryClient.clear()
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshQuery, refreshQuery.isError, refreshQuery.failureCount, refreshQuery.error])




    const hotelDataQuery = useHotelQuery();
    const hospDataQuery = useHospitalQuery();
    const userDataQuery = useUserListQuery();
    const regionDataQuery = useRegionQuery();

    const [modalOpen, setModalOpen] = useState(false);


    // Top Bar Height = 78 (LiveSearch) + 60 (AppBar) style = {{height:(height - 78- 60), maxHeight:(height - 78- 60)}}

    const columnsHosp = AdminMode ? [
        { field: 'Name', header: TextLanguage['HospitalName'][lang] },
        { field: 'Phone', header: TextLanguage['Phone'][lang] },
        { field: 'Fax', header: TextLanguage['Fax'][lang] },
        { field: 'Email', header: TextLanguage['Email'][lang] },
        { field: 'mailInfo', header: TextLanguage['OperationEmail'][lang] },
        { field: 'mailPayment', header: TextLanguage['PaymentEmail'][lang] },
        { field: 'Notes', header: TextLanguage['Notes'][lang] },
        { field: 'Type', header: TextLanguage['Type'][lang] },
        { field: 'Region', header: TextLanguage['Region'][lang] },
        { field: 'Lat', header: TextLanguage['Latitude'][lang] },
        { field: 'Lng', header: TextLanguage['Longitude'][lang] },
        { field: 'disabled', header: TextLanguage['Active'][lang] },
        { field: 'worksWithTL' , header: 'TL' },
        { field: 'userID', header: 'UserID' },
        { field: 'userIDLabel', header: 'User' },
        { field: '_id', header: 'HospID' },

    ] : ExpertiseMode ? [
        { field: 'Name', header: TextLanguage['HospitalName'][lang] },
        { field: 'Phone', header: TextLanguage['Phone'][lang] },
        { field: 'Fax', header: TextLanguage['Fax'][lang] },
        { field: 'Email', header: TextLanguage['Email'][lang] },
        { field: 'mailInfo', header: TextLanguage['OperationEmail'][lang] },
        { field: 'mailPayment', header: TextLanguage['PaymentEmail'][lang] },
        { field: 'Notes', header: TextLanguage['Notes'][lang] },
        { field: 'Type', header: TextLanguage['Type'][lang] },
        { field: 'worksWithTL' , header: 'TL' }


    ] : [
        { field: 'Name', header: TextLanguage['HospitalName'][lang] },
        { field: 'Phone', header: TextLanguage['Phone'][lang] },
        { field: 'Fax', header: TextLanguage['Fax'][lang] },
        { field: 'Email', header: TextLanguage['Email'][lang] },
        { field: 'mailInfo', header: TextLanguage['OperationEmail'][lang] },
        { field: 'mailPayment', header: TextLanguage['PaymentEmail'][lang] },
        { field: 'Notes', header: TextLanguage['Notes'][lang] },
        { field: 'Type', header: TextLanguage['Type'][lang] },
        { field: 'Region', header: TextLanguage['Region'][lang] },
        { field: 'disabled', header: TextLanguage['Active'][lang] },
    ];
    const columnsHotel = [
        { field: 'Name', header: TextLanguage['Hotel Name'][lang] },
        { field: 'Phone', header: TextLanguage['Phone'][lang] },
        { field: 'Fax', header: TextLanguage['Fax'][lang] },
        { field: 'Reference', header: TextLanguage['Reference'][lang] },
        { field: 'Contract', header: TextLanguage['Contract'][lang] },
        { field: 'Area', header: TextLanguage['Area'][lang] },
        { field: 'Region', header: TextLanguage['Region'][lang] },
        { field: 'Lat', header: TextLanguage['Latitude'][lang] },
        { field: 'Lng', header: TextLanguage['Longitude'][lang] },
    ];
    const columnsUser =  AdminMode ? [
        { field: 'HospitalName', header: TextLanguage['HospitalName'][lang] },
        { field: 'username', header: TextLanguage['Username'][lang] },
        { field: 'mailInfo', header: TextLanguage['OperationEmail'][lang] },
        { field: 'mailPayment', header: TextLanguage['PaymentEmail'][lang] },
        { field: 'disabled', header: TextLanguage['Active'][lang] },
        // { field: 'mailAuth', header: "2FA" },
        { field: 'multipleHospital', header: 'Multiple Hospitals' },
    ] : [
        { field: 'HospitalName', header: TextLanguage['HospitalName'][lang] },
        { field: 'username', header: TextLanguage['Username'][lang] },
        { field: 'mailInfo', header: TextLanguage['OperationEmail'][lang] },
        { field: 'mailPayment', header: TextLanguage['PaymentEmail'][lang] },
        { field: 'disabled', header: TextLanguage['Active'][lang] },
    ]





    const hospitalMutation = useUpdateHospitalMutation({ modal: false, setsnackbarStatus });


    const onCellEditCompleteHospital = debounce((e) => {
        let { rowData, newRowData, value, newValue, field, originalEvent: event } = e;

        if (field === 'Region') { return }
        setCellEditorModalState({ open: false, type: null, data: null })
        if (value !== newValue) {

            queryClient.setQueryData(['hospitalData'], (prevData) => {
                //console.log('setQueryData:', prevData);
                const updatedData = prevData?.data?.map((hosp) =>
                    hosp._id === newRowData._id ? newRowData : hosp
                );
                return { ...prevData, data: updatedData };
            });

            const updaterData = {
                ...newRowData,
                Lng: parseFloat(newRowData.Lng),
                Lat: parseFloat(newRowData.Lat),
            }

            hospitalMutation.mutate({ updaterData: updaterData, oldRowData: rowData });

        }
    }, 25);

    const [cellEditorModalState, setCellEditorModalState] = useState({ open: false, type: null, data: null });

    const cellEditorHotel = (options, fieldName) => {
        switch (fieldName) {
            case "Contract":
                return (
                    <FormField
                        id="select-Contract"
                        select
                        value={options.value ? options.value : ''}
                        onChange={(e) => {
                            options.editorCallback(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                //console.log(e);
                                handleDropdownSelect(options, 'Contract', 'hotel');
                            }
                        }}
                    >
                        <MenuItem value={null}>None</MenuItem>
                        {hospDataQuery?.data?.data?.map((option) => (
                            <MenuItem key={`Cont-Hosp-Opt-${option?.Name}`} value={option?.Name}>
                                {option?.Name}
                            </MenuItem>
                        ))}
                    </FormField>
                )

            case "Region":
                return (
                    <FormField
                        id="select-Region"
                        select
                        value={options.value ? options.value : ''}
                        onChange={(e) => {
                            options.editorCallback(e.target.value);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleDropdownSelect(options, 'Region', 'hotel');
                            }
                        }}
                    >
                        <MenuItem value={null}>None</MenuItem>
                        {regionDataQuery?.data?.data?.map((option) => (
                            <MenuItem key={`Reg-Opt-${option?.Region}`} value={option?.Region}>
                                {option?.Region}
                            </MenuItem>
                        ))}
                    </FormField>
                )

            default:
                cellEditorModalState.data === null && setCellEditorModalState({ open: false, type: 'Text', data: options });
                return (<TextField sx={{
                    width: '100%', fontSize: '1rem !important', background: '#0b4d9961',
                    '& .MuiInputBase-root': {
                        fontSize: '1rem !important',

                        '& .MuiInputBase-input': {
                            textEmphasis: 'none',
                            padding: '0.5rem 0.5rem',
                            fontSize: '1rem !important',
                        }
                    }
                }} value={options.value ? options.value : ''} onChange={(e) => options.editorCallback(e.target.value)} />);
        }

    };

    const hospTypes = [
        { Type: 'Hospital' },
        { Type: 'Clinic' },
        { Type: 'MedCenter' },
        { Type: 'Dentist' },
        { Type: 'Doctor' }
    ]

    const cellEditorHospital = (options, fieldName) => {
        switch (fieldName) {
            case "Region":
                return (<Button sx={{
                    background: 'rgb(62, 63, 66)', '&:hover': { background: 'rgb(87, 87, 87)' },
                    color: 'white', width: '100%'
                }}
                    onClick={() => setCellEditorModalState({ open: true, type: 'Region', data: options })}>
                    Edit Region
                </Button>)

            case "Type":


                return (<FormField
                    id="select-Type"
                    select
                    value={options.value ? options.value : ''}
                    onChange={(e) => {
                        options.editorCallback(e.target.value);
                    }}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            //console.log(e);
                            handleDropdownSelect(options, 'Type', 'hospital');
                        }
                    }}
                >
                    {hospTypes.map((option) => (
                        <MenuItem key={`hospTypes-${option?.Type}`} value={option?.Type}>
                            {option?.Type}
                        </MenuItem>
                    ))}
                </FormField>)
            /*
                            return (
                                <Button sx={{
                                    background: 'rgb(62, 63, 66)', '&:hover': { background: 'rgb(87, 87, 87)' },
                                    color: 'white', width: '100%'
                                }}
                                    onClick={() => setCellEditorModalState({ open: true, type: 'Type', data: options })}>
                                    Edit Type
                                </Button>
                            )
            */

            default:
                cellEditorModalState.data === null && setCellEditorModalState({ open: false, type: 'Text', data: options });
                return (<TextField sx={{
                    width: '100%', fontSize: '1rem !important', background: '#0b4d9961',
                    '& .MuiInputBase-root': {
                        fontSize: '1rem !important',

                        '& .MuiInputBase-input': {
                            textEmphasis: 'none',
                            padding: '0.5rem 0.5rem',
                            fontSize: '1rem !important',
                        }
                    }
                }} value={options.value ? options.value : ''} onChange={(e) => options.editorCallback(e.target.value)} />);
        }

    };

    const handleDropdownSelect = (options, fieldName, listName) => (e) => {
        //console.log(options, e);
        //options.editorCallback(e.target.value);
        const updaterData = { // Sanitize Data
            ...options.rowData,
            [fieldName]: e.target.value,
            Lng: parseFloat(options.rowData.Lng),
            Lat: parseFloat(options.rowData.Lat),
        }

        listName === 'hotel' && hotelMutation.mutate({ updaterData: updaterData, oldRowData: options.rowData });
        listName === 'hospital' && hospitalMutation.mutate({ updaterData: updaterData, oldRowData: options.rowData });

        datatableRef.current.closeEditingCell();

    }

    const hotelMutation = useUpdateHotelMutation({ searchMode: false, setsnackbarStatus });


    const onCellEditCompleteHotel = debounce((e) => {
        let { rowData, newRowData, value, newValue, field, originalEvent: event } = e;

        setCellEditorModalState({ open: false, type: null, data: null })

        if (value !== newValue) {

            queryClient.setQueryData(['hotelData'], (prevData) => { // Optimistic Update
                const updatedData = prevData?.data?.map((hotel) =>
                    hotel._id === newRowData._id ? newRowData : hotel
                );
                return { ...prevData, data: updatedData };
            });

            const updaterData = { // Sanitize Data
                ...newRowData,
                Lng: parseFloat(newRowData.Lng),
                Lat: parseFloat(newRowData.Lat),
            }

            hotelMutation.mutate({ updaterData: updaterData, oldRowData: rowData }); // Push to backend

        }
    }, 25);


    const userMutation = useUpdateUserMutation({ setsnackbarStatus });


    const cellEditorUser = (options, fieldName) => {
        switch (fieldName) {


            default:
                //cellEditorModalState.data === null && setCellEditorModalState({ open: false, type: 'Text', data: options });
                return (<TextField sx={{
                    width: '100%', fontSize: '1rem !important', background: '#0b4d9961',
                    '& .MuiInputBase-root': {
                        fontSize: '1rem !important',

                        '& .MuiInputBase-input': {
                            textEmphasis: 'none',
                            padding: '0.5rem 0.5rem',
                            fontSize: '1rem !important',
                        }
                    }
                }} value={options.value ? options.value : ''} onChange={(e) => options.editorCallback(e.target.value)} />);
        }

    };


    const onCellEditCompleteUser = debounce((e) => {
        let { rowData, newRowData, value, newValue, field, originalEvent: event } = e;

        //setCellEditorModalState({ open: false, type: null, data: null })

        if (value !== newValue) {

            queryClient.setQueryData(['userData'], (prevData) => { // Optimistic Update
                const updatedData = prevData?.data?.map((user) =>
                    user._id === newRowData._id ? newRowData : user
                );
                return { ...prevData, data: updatedData };
            });

            const updaterData = {
                username: newRowData.username,
                mailInfo: newRowData.mailInfo,
                mailPayment: newRowData.mailPayment,
                multipleHospital: newRowData.multipleHospital,
            }

            userMutation.mutate({ updaterData: updaterData, oldRowData: rowData }); // Push to backend

        }
    }, 25);


    const rowClass = (data) => {

        return {
            'selectedClass': data?._id === cellEditorModalState?.data?.rowData?._id,
        };
    };


    const [selectedTab, setSelectedTab] = useState('hospListTab');


    const formatTableTextElement = (fieldName) => (rowData) => {
        return (<Typography
            sx={{
                fontSize: '1rem !important', paddingInline: '0.5rem', color: fieldName === 'Contract' ? 'rgb(125,188,255)' : 'white',
                fontWeight: ['Type', 'Region', 'Contract', 'HospitalName', 'username'].includes(fieldName) ? 'bold' : 'normal'
            }}>
            {rowData[fieldName]}
        </Typography>)
    };
    const formatUserIDLabel  = (rowData) => {
        const userID = rowData?.userID;
        const user = userDataQuery?.data?.data?.find((user) => user?.userID === userID);


        return (<Typography
            sx={{
                fontSize: '1rem !important', paddingInline: '0.5rem', color: 'white',
                fontWeight:  'bold'
            }}>
            {user?.HospitalName}
        </Typography>)
    };
    const formatTableTextElementRegionArray = (rowData) => {
        return (<div style={{ display: 'flex', flexDirection: 'row', gap: '0.25rem' }}>


            <Typography sx={{ fontSize: '1rem !important', paddingLeft: '0.5rem', fontWeight: 'bold' }}>
                {rowData?.Region[0]}{ }
            </Typography>
            <Typography sx={{ fontSize: '1rem !important', fontWeight: 'bold', color: 'rgb(4, 236, 131)' }}>
                {rowData?.Region[1] ? `+${rowData?.Region.length - 1}` : ''}
            </Typography>

        </div>
        )


    };

    const formatBoolElement = (fieldName, type, invert, disableButton) => (rowData) => {
        let bool = !!rowData[fieldName];
        if (invert) {
            bool = !bool;
        }
        return (
            <Button onClick={handleBooleanToggle(rowData, fieldName, type)} sx={{ width: '100%', padding: '0px 12px', color: bool ? 'green' : 'red',
                pointerEvents: disableButton ? 'none' : 'auto',
                '&:hover': { background: 'transparent', color: bool ? 'green' : 'red' },
                userSelect: disableButton ? 'none' : 'auto',

            }}>
                {bool ? <CheckIcon /> : <ClearIcon />}
            </Button>
        )
    }

    const handleBooleanToggle = (rowData, field, type) => debounce((e) => {
        console.log(field, type, rowData);
        const newRowData = { ...rowData, [field]: !rowData[field] };
        console.log('newRowData', newRowData);
        if (type === 'hospital') {

            queryClient.setQueryData(['hospitalData'], (prevData) => {
                //console.log('setQueryData:', prevData);
                const updatedData = prevData?.data?.map((hosp) =>
                    hosp._id === newRowData._id ? newRowData : hosp
                );
                return { ...prevData, data: updatedData };
            });

            const updaterData = {
                ...newRowData,
                Lng: parseFloat(newRowData.Lng),
                Lat: parseFloat(newRowData.Lat),
            };
            console.log(updaterData);
            hospitalMutation.mutate({ updaterData: updaterData, oldRowData: rowData });

        } else if (type === 'user') {

            queryClient.setQueryData(['userData'], (prevData) => {
                //console.log('setQueryData:', prevData);
                const updatedData = prevData?.data?.map((user) =>
                    user._id === newRowData._id ? newRowData : user
                );
                return { ...prevData, data: updatedData };
            });
            console.log('updater:', { updaterData: newRowData, oldRowData: rowData });
            userMutation.mutate({ updaterData: newRowData, oldRowData: rowData });


        }





    }, 25);

    const copyToClipboard = (rowData) => {

        const clipboardData =
            selectedTab === 'hospListTab' ?
                `${rowData?.Name ? rowData?.Name : ''}\t${rowData?.Phone ? rowData?.Phone : ''}\t${rowData?.Fax ? rowData?.Fax : ''}\t${rowData?.Email ? rowData?.Email : ''}\t${rowData?.Type ? rowData?.Type : ''}`
                :
                `${rowData?.Name ? rowData?.Name : ''}\t${rowData?.Phone ? rowData?.Phone : ''}\t${rowData?.Fax ? rowData?.Fax : ''}\t${rowData?.Reference ? rowData?.Reference : ''}\t${rowData?.Contract ? rowData?.Contract : ''}\t${rowData?.Area ? rowData?.Area : ''}`;
        navigator.clipboard.writeText(clipboardData);
    }

    const hotelRemoveMutation = useRemoveHotelMutation({ setModalOpen, setsnackbarStatus });
    const hospitalRemoveMutation = useRemoveHospitalMutation({ setModalOpen, setsnackbarStatus });

    const removeEntry = (rowData) => {
        if (rowData?._id === undefined || rowData?._id === '') return;
        selectedTab === 'hospListTab' ?
            hospitalRemoveMutation.mutate({ updaterData: { _id: rowData?._id } })
            :
            hotelRemoveMutation.mutate({ updaterData: { _id: rowData?._id } })

    }




    const contextMenuRef = useRef(null);
    const [rightselectedRow, setrightselectedRow] = useState(null);
    const menuModel = [

        {
            label:
                <Typography sx={{ padding: '0px !important', fontSize: '1rem', fontWeight: 'bold' }}>
                    Copy Row
                </Typography>,

            command: () => copyToClipboard(rightselectedRow)
        },
        {
            label:
                <Typography sx={{ padding: '0px !important', fontSize: '1rem', fontWeight: 'bold' }}>
                    Clear Selection
                </Typography>
            , command: () => setrightselectedRow(null)
        },
        { separator: true },
        {
            label: <Typography sx={{ color: 'red', padding: '0px !important', fontSize: '1rem', fontWeight: 'bold' }}>
                Remove
            </Typography>, command: () => removeEntry(rightselectedRow)
        },

    ];
    const [searchFilter, setSearchFilter] = useState({})
    const onTextFilter = (field) => (filter) => {
        setSearchFilter({ ...searchFilter, ...{ [field]: filter } });
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div className="HospSearchMain">


                {(MapboxAPIKeyQuery?.isSuccess && regionDataQuery?.isSuccess && hospDataQuery?.isSuccess) && <Modal
                    open={modalOpen}
                    onClose={() => { setModalOpen(false); }}
                    id={`NewDataEntryModal`}

                >




                    {


                        ((selectedTab === 'hospListTab') ?
                            <Box sx={[ModalStyle, { width: '95%', minWidth: '65%', height: '97%', padding: '1rem' }]}>

                                <HospitalCreateForm
                                    mapboxAPIKey={MapboxAPIKeyQuery?.data?.data?.mapboxAPIKey}
                                    setsnackbarStatus={setsnackbarStatus}
                                    regionDataQuery={regionDataQuery}
                                    setModalOpen={setModalOpen}
                                    lang={lang}
                                />
                            </Box>
                            :
                            (selectedTab === 'hotelListTab') ?
                                <Box sx={[ModalStyle, { width: '95%', minWidth: '65%', height: '97%', padding: '1rem' }]}>
                                    <HotelCreateForm
                                        mapboxAPIKey={MapboxAPIKeyQuery?.data?.data?.mapboxAPIKey}
                                        setsnackbarStatus={setsnackbarStatus}
                                        hospDataQuery={hospDataQuery}
                                        regionDataQuery={regionDataQuery}
                                        setModalOpen={setModalOpen}
                                        lang={lang}
                                    />
                                </Box>
                                :
                                <Box sx={[ModalStyle, { minWidth: '640px', height: '97%', padding: '1rem' }]}>
                                    <UserCreateForm
                                        mapboxAPIKey={MapboxAPIKeyQuery?.data?.data?.mapboxAPIKey}
                                        setsnackbarStatus={setsnackbarStatus}
                                        hospDataQuery={hospDataQuery}
                                        regionDataQuery={regionDataQuery}
                                        setModalOpen={setModalOpen}
                                        lang={lang}
                                    />
                                </Box>
                        )


                    }


                </Modal>}

                <Modal
                    open={cellEditorModalState?.open}
                    onClose={() => { setCellEditorModalState({ open: false, type: null, data: null }); }}
                    id={`CellEditorModal`}>

                    <RegionSelectorDialog
                        regionDataQuery={regionDataQuery}
                        setsnackbarStatus={setsnackbarStatus}
                        cellEditorModalState={cellEditorModalState}
                        setCellEditorModalState={setCellEditorModalState}
                    />

                </Modal>

                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

                <AppBar
                    lang={lang}
                    setlang={setlang}
                    currentPage={currentPage}
                />

                <div className='top-search-container'
                    style={{
                        background: '#3c3c3c', padding: '0px', height: '70px',
                        justifyContent: 'space-between', paddingRight: '1.5rem'
                    }}>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1.5rem', height: '100%' }}>

                        <FormTabs value={selectedTab} onChange={(event, newValue) => { setSelectedTab(newValue) }} >
                            <FormTab label={TextLanguage['Hospitals'][lang]} id='hospListTab' value="hospListTab" />
                            <FormTab label={TextLanguage['Hotels'][lang]} id='hotelListTab' value="hotelListTab" />
                            <FormTab label={TextLanguage['Users'][lang]} id='userListTab' value="userListTab" />
                        </FormTabs>

                        <TextFilter dense={true} labelName={TextLanguage['SearchByName'][lang]} onFilter={onTextFilter('Name')} />
                    </div>
                    <div>
                        {selectedTab === 'hospListTab' ?
                            <NewPatientButton onClick={() => setModalOpen(true)}>
                                {TextLanguage['NewHospital'][lang]}
                            </NewPatientButton>
                            :
                            selectedTab === 'hotelListTab' ?
                                <NewPatientButton onClick={() => setModalOpen(true)}>
                                    {TextLanguage['NewHotel'][lang]}
                                </NewPatientButton>
                                :
                                <NewPatientButton onClick={() => setModalOpen(true)}>
                                    {TextLanguage['NewUser'][lang]}
                                </NewPatientButton>
                        }
                    </div>
                </div>

                <div className='DataEditorTableContainer'  >
                    <ContextMenu model={menuModel} ref={contextMenuRef} onHide={() => setrightselectedRow(null)} />

                    <DataTable
                        key={`dataTableKey-${dataTableKey}`}
                        onContextMenu={(e) => contextMenuRef.current.show(e.originalEvent)}
                        contextMenuSelection={rightselectedRow}
                        onContextMenuSelectionChange={(e) => setrightselectedRow(e.value)}
                        id='divTable'
                        ref={datatableRef}
                        selectionMode="single"
                        metaKeySelection={false}
                        removableSort
                        stripedRows
                        scrollable
                        scrollHeight="flex"
                        showGridlines
                        resizableColumns
                        footer={<div style={{ display: 'block', width: '100%', height: '1rem' }} />}
                        rowClassName={rowClass}
                        virtualScrollerOptions={{ itemSize: 46 }}
                        size="small"
                        dataKey="_id"
                        className="DataGrid"
                        value={
                            selectedTab === 'hospListTab' ?
                                filteredList(hospDataQuery?.data?.data, searchFilter?.Name?.value, ['Name'])
                                :
                                selectedTab === 'hotelListTab' ?
                                    filteredList(hotelDataQuery?.data?.data, searchFilter?.Name?.value, ['Name'])
                                    :
                                    filteredList(userDataQuery?.data?.data, searchFilter?.Name?.value, ['HospitalName'])
                        }
                        editMode="cell"
                        style={{ width: '100%' }}
                        tableStyle={{ width: '100%', minWidth: '50rem' }}

                    >
                        {
                            selectedTab === 'hospListTab' ? columnsHosp.map((col) => {

                                if (col.field === 'Region') { // Dropdown or Modal Editors
                                    return <Column key={col.field} field={col.field} header={col.header}
                                        style={defaultColumnStyle(120)}
                                        bodyClassName='ColumnBody'

                                        body={formatTableTextElementRegionArray}
                                        editor={(options) => cellEditorHospital(options, col.field)} onCellEditComplete={onCellEditCompleteHospital} />
                                } else if (col.field === 'Type') { // Dropdown or Modal Editors
                                    return <Column key={col.field} field={col.field} header={col.header}
                                        style={defaultColumnStyle(70)}
                                        bodyClassName='ColumnBody'
                                        onCellEditInit={(e) => { return false }}
                                        body={formatTableTextElement(col.field)}
                                        editor={(options) => cellEditorHospital(options, col.field)} onCellEditComplete={onCellEditCompleteHospital} />
                                } else if (col.field === 'disabled') {
                                    return <Column
                                        key={col.field}
                                        field={col.field}
                                        header={col.header}
                                        style={{ width: '4%', minWidth: '64px', maxWidth: '64px' }}
                                        bodyClassName='ColumnBody'
                                        sortable
                                        body={formatBoolElement(col.field, 'hospital', true, false)}
                                    />
                                } else if (col.field === 'worksWithTL') {
                                    return <Column
                                        key={col.field}
                                        field={col.field}
                                        header={col.header}
                                        style={{ width: '4%', minWidth: '64px', maxWidth: '64px' }}
                                        bodyClassName='ColumnBody'
                                        sortable
                                        body={formatBoolElement(col.field, 'hospital', false, false)}
                                    />

                                } else if (col.field === 'userIDLabel') {
                                    return <Column key={col.field} field={col.field} header={col.header}
                                    style={defaultColumnStyle(120)}
                                    sortable
                                    bodyClassName='ColumnBody'
                                    body={formatUserIDLabel}
                                    editor={(options) => cellEditorHospital(options, col.field)} onCellEditComplete={onCellEditCompleteHospital} />


                                } else {
                                    return <Column key={col.field} field={col.field} header={col.header}
                                    style={defaultColumnStyle(120)}
                                    sortable
                                    bodyClassName='ColumnBody'
                                    body={formatTableTextElement(col.field)}
                                    editor={(options) => cellEditorHospital(options, col.field)} onCellEditComplete={onCellEditCompleteHospital} />
                                }


                            }
                            )
                                :
                                selectedTab === 'hotelListTab' ?
                                    columnsHotel.map((col) => {

                                        if (col.field === 'Contract' || col.field === 'Region') {

                                            return (<Column key={col.field} field={col.field} header={col.header}
                                                style={defaultColumnStyle(120)}
                                                sortable
                                                bodyClassName='ColumnBody'
                                                onCellEditInit={(e) => { return false }}
                                                body={formatTableTextElement(col.field)}
                                                editor={(options) => cellEditorHotel(options, col.field)} onCellEditComplete={onCellEditCompleteHotel} />)
                                        }

                                        return (<Column key={col.field} field={col.field} header={col.header}
                                            style={defaultColumnStyle(120)}
                                            sortable
                                            bodyClassName='ColumnBody'

                                            body={formatTableTextElement(col.field)}
                                            editor={(options) => cellEditorHotel(options, col.field)} onCellEditComplete={onCellEditCompleteHotel} />)
                                    })
                                    :
                                    columnsUser.map((col) => {

                                        if (col.field === 'username' || col.field === 'HospitalName') {

                                            return (<Column key={col.field} field={col.field} header={col.header}
                                                style={defaultColumnStyle(120)}
                                                sortable
                                                bodyClassName='ColumnBody'
                                                body={formatTableTextElement(col.field)}
                                            />)
                                        } else if (col.field === 'disabled') {
                                            return <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                style={{ width: '4%', minWidth: '64px', maxWidth: '64px' }}
                                                bodyClassName='ColumnBody'
                                                sortable
                                                body={formatBoolElement("disabled", 'user', true, true)}
                                            />
                                        } else {
                                            return (<Column key={col.field} field={col.field} header={col.header}
                                                style={defaultColumnStyle(120)}
                                                sortable
                                                bodyClassName='ColumnBody'
                                                body={formatTableTextElement(col.field)}
                                                editor={(options) => cellEditorUser(options, col.field)} onCellEditComplete={onCellEditCompleteUser} />)
                                        }

                                        /*
                                          else if(col.field === 'mailAuth'){
                                            return <Column
                                                key={col.field}
                                                field={col.field}
                                                header={col.header}
                                                style={{ width: '4%', minWidth: '64px', maxWidth: '64px' }}
                                                bodyClassName='ColumnBody'
                                                sortable
                                                body={formatBoolElement("mailAuth", 'user', false, false)}
                                            />

                                        }
                                        */


                                    })




                        }

                        <Column rowEditor={true} headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                    </DataTable>
                </div>
            </div>

        </LocalizationProvider>
    )


}










