
import MuiAutocomplete from '@mui/material/Autocomplete';
import { styled } from '@mui/system';

export const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
    background: 'transparent',


    color: theme.palette.text.primary,
    '& .MuiAutocomplete-listbox': {
        background: 'red',
        margin: 50
    },
    '& .MuiAutocomplete-clearIndicator': {
        color: 'red',
        top: -2,
        right: 30,
    },

    '& .MuiAutocomplete-inputFocused': {
        color: theme.palette.text.primary,
        background: theme.palette.dark[4],
    },
    '& .MuiAutocomplete-endAdornment': {
        color: theme.palette.errorColor.main,
        top: 8,
    },
    '& .MuiAutocomplete-listbox': {
        margin: 50
    },
    width: "100%",
    // height: 78,
    padding: '0px',
    '& .MuiInputLabel-root': {
        color: theme.palette.text.primary,
            fontSize: '0.9rem',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '12px',
      color: theme.palette.text.primary,

      background: theme.palette.mainColor.Transp,
      '& .Mui-expanded': {
        background: theme.palette.mainColor.Transp
      },
    },
}));
