import {
    MenuItem, Select,
    FormControl,
    Typography
} from '@mui/material';
import remToPx from '../functions/remToPx';

import { styled } from '@mui/material/styles';


const StyledSelect = styled(Select)(({ theme }) => ({


    '&:hover, &.Mui-focusVisible': {
        background: 'linear-gradient(to bottom, rgba(52,84,129,0.8), rgba(66, 151, 255, 0.78))',
        boxShadow: 'inset 0px 0px 12px -2px rgb(125,188,255)',

    },
    '.MuiSelect-select': {
        display: 'flex !important',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    }
}));



export const LanguageSelector = ({lang, setlang}) => {

    const handleLangChange = (event) => { setlang(event.target.value) };

    return <FormControl variant="filled" sx={{
        background: 'rgba(45,45,45,0.5)', m: 0, minWidth: '150px', display: 'flex', alignSelf: 'center',
        '& .MuiInputBase-root': {
            background: 'linear-gradient(to bottom, rgb(70, 70, 70), rgb(32, 32, 32))',
        }
        }}>

        <StyledSelect
            className="LangSelect"
            sx={{ backgroundColor: 'rgba(34, 34, 37, 0.5)', display: 'flex !important', flexDirection:'row !important', alignContent: 'center', padding: '0px !important' }}
            labelId="langselect-label"
            id="langselect"
            value={lang}
            label="Language"
            onChange={handleLangChange}
        >
            <MenuItem value={'trTR'}> <span className="fi fi-tr"></span> <Typography sx={{ color: 'white', m: 0, marginLeft: 1 }}>Türkçe</Typography></MenuItem>
            <MenuItem value={'enUS'}> <span className="fi fi-gb "></span> <Typography sx={{ color: 'white', marginLeft: 1 }}> English</Typography></MenuItem>
            <MenuItem value={'ruRU'}> <span className="fi fi-ru "></span> <Typography sx={{ color: 'white', marginLeft: 1 }}> Pусский</Typography></MenuItem>
        </StyledSelect>
    </FormControl>;
}
