
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useUpdateHospitalMutation = ({ modal=false, setOpenModal,setsnackbarStatus,
   modalCellEditor=false, cellEditorModalState, setCellEditorModalState
}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData, oldRowData }) => {


            return axios.put(`${config.API_URL}/operator/updateHospital`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['hospitalData'], refetchType: 'active'}, {cancelRefetch: false });
            queryClient.invalidateQueries({ queryKey: ['userData'] , refetchType: 'active'}, {cancelRefetch: false});
            modal && setOpenModal(false);
            modalCellEditor && setCellEditorModalState({...cellEditorModalState, open: false})

        },
        onError: (error, variables) => {
            setsnackbarStatus({ open: true, message: 'There was an error during the update', severity: 'error' });
            queryClient.setQueryData(['hospitalData'], (prevData) => {
                //console.log('setQueryData:', prevData);
                const updatedData = prevData?.data?.map((hosp) =>
                    hosp._id === variables.oldRowData._id ? variables.oldRowData : hosp
                );
                return { ...prevData, data: updatedData };
            });
        }
    });
}
