
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import config from "../config.js";




export const useValidateQuery = () => {




    async function validate() {

            return await axios.get(`${config.API_URL}/login/validate`, {
                withCredentials: true,
            });


    };

    return useQuery(
        {
            queryKey: ['validate'],
            queryFn: validate,
            staleTime: Infinity,
            cacheTime: Infinity,
            gcTime: Infinity,
            refetchInterval: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        });




}