
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useCreateHospitalMutation = ({setModalOpen, setsnackbarStatus}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {

            return axios.post(`${config.API_URL}/operator/addHospital`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['hospitalData'] , refetchType: 'active'}, {cancelRefetch: false});
            queryClient.invalidateQueries({ queryKey: ['userData'] , refetchType: 'active'}, {cancelRefetch: false});
            setModalOpen(false);
            const userData = data?.data;
            setsnackbarStatus({ open: true, message: `Username: ${userData.username} Password: ${userData.password}`, severity: 'success' });


            //data?.data?.blobContainerStatus
        },
        onError: (error, variables) => {
            setsnackbarStatus({ open: true, message: 'There was an error during the update', severity: 'error' });

        }
    });
}
