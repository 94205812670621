
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useCreateHotelMutation = ({setModalOpen, setsnackbarStatus}) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {


            return axios.post(`${config.API_URL}/operator/addHotel`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['hotelData'] , refetchType: 'active'}, {cancelRefetch: false});
            setModalOpen(false);
        },
        onError: (error, variables) => {
            setsnackbarStatus({ open: true, message: 'There was an error during the update', severity: 'error' });

        }
    });
}
