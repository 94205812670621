import React from 'react';
import { FixedSizeList } from 'react-window';
import { Box, Divider, Chip, TextField, useTheme, Typography } from '@mui/material';
import remToPx from '../../functions/remToPx';

const itemSize = remToPx(3);


const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {

    const theme = useTheme();

function renderRow(props) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const key = `${dataSet[1].Name}-${index}`;

    return (
        <Box
            key={key}
            sx={{

                position: 'absolute',
                top: style.top + itemSize / 2,
                width: '100%',
                alignItems: 'center', // Center items horizontally
                justifyContent: 'center', // Center items vertically
                color: 'white', marginBottom: 2, paddingLeft: 4, paddingBottom: 10, height: itemSize,

                display: 'flex', flexDirection: 'column'
            }}
            component="li" {...dataSet[0]}
            className="searchBox">
            <div key={`${key}-BlockBox`} style={{ display: 'flex', flexDirection:'row', width: '100%', justifyContent: 'flex-start', alignItems: 'center'}}>
                <Typography key={`${key}-Typography`} style={{ fontSize: '0.9rem' }}>{dataSet[1].Name} </Typography>
                <Chip key={`${key}-chip`} variant="outlined"
                    sx={{
                        border: '1px solid var(--color-light-gray-tint)',
                        color: 'var(--color-text)',
                        alignItems: 'center',
                        justifyContent: 'center',
                        '& .MuiChip-label': { paddingInline: '0.75rem', alignSelf:'center' },
                        height: itemSize / 2,
                        background: theme.palette.dark[4],
                        fontWeight: 900, fontSize: '0.5rem', margin: 2, zIndex: 100

                    }}
                    label={dataSet[1].Area.toUpperCase()} />
            </div>
            <Divider sx={{ position: 'absolute', left: 0, top: itemSize / 2 - 6, width: '100%', height: 2, background: theme.palette.dark[2] }}></Divider>
        </Box>
    )
}




    const { children, ...other } = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const itemCount = itemData.length;

    const listRef = React.useRef();

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <FixedSizeList
                    key={`VarList-${itemCount}`}
                    style={{ overflow: 'auto', height: '100%', margin: '0px !important', padding: '0px !important', background: theme.palette.dark[0] }}
                    itemData={itemData}
                    height={800}
                    width="100%"
                    ref={listRef}
                    outerElementType={OuterElementType}
                    innerElementType={"ul"}
                    itemSize={remToPx(2.5)}
                    overscanCount={50}
                    itemCount={itemCount}
                >
                    {renderRow}
                </FixedSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

export default ListboxComponent;