import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import EditIcon from '@mui/icons-material/Edit';
import { Button, Typography } from '@mui/material';
import TextLanguage from '../data/TextLanguage.json';
import { AccordionDetails } from '../StyledComponents/AccordionDetails';
import { AccordionSummary } from '../StyledComponents/AccordionSummary';
import { Accordion } from '../StyledComponents/Accordion';

export const HotelAccordion = ({
    handleAccordChange, expanded,
    expandAll, lang, handleEditFormOpen, selectedValue
}) => {
    return <Accordion className="accordClass"
        onChange={handleAccordChange(-1)}
        expanded={(expanded === -1) || expandAll}>
        <AccordionSummary
            key='hotelAccordion'
            bgcolor1='rgb(49,80,125)'
            bgcolor2='rgba(45,45,45,0.6)'
            expandIcon={<ExpandCircleDownIcon sx={{ fontSize: '2.25rem', padding: '2px', transform: 'rotate(270deg)', color: 'rgb(125,188,255)' }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
        >
            <div className='HotelAccordionTopContainer' >
                <Typography sx={{ marginLeft: 2, fontSize: '1.25rem', color: 'white' }}>{TextLanguage["Hotel Information"][lang]}</Typography>

                <Button sx={{
                    color: 'white',
                    '&:hover': {
                        background: 'none',
                        color:'orange'
                    }
                }} disableRipple onClick={handleEditFormOpen}><EditIcon sx={{ color: 'inherit', marginLeft: '20px', fontSize: '2rem', }} /></Button>
            </div>
        </AccordionSummary>
        <AccordionDetails sx={{ fontSize: '1.15rem' }}>
            {
                //Format these
            }
            {selectedValue && (
                <>
                    {selectedValue.Phone && (
                        <>
                            <span style={{ color: 'red' }}>{TextLanguage["Phone"][lang]}: </span>
                            <span>{selectedValue.Phone}</span>
                        </>
                    )}
                    {selectedValue.Phone && <br />}
                    {selectedValue.Fax && (
                        <>
                            <span style={{ color: 'red' }}>{TextLanguage["Fax"][lang]}: </span>
                            <span>{selectedValue.Fax}</span>
                        </>
                    )}
                    {selectedValue.Fax && <br />}
                    {selectedValue.Reference && (
                        <>
                            <span style={{ color: 'red' }}>{TextLanguage["Reference"][lang]}: </span>
                            <span>{selectedValue.Reference}</span>
                        </>
                    )}
                </>
            )}

            <Typography>{selectedValue?.NameComm}</Typography>
            <Typography>{selectedValue?.AreaComm}</Typography>
            <Typography>{selectedValue?.PhoneComm}</Typography>
            <Typography>{selectedValue?.FaxComm}</Typography>
            <Typography>{selectedValue?.ReferenceComm}</Typography>
            <Typography>{selectedValue?.ContractComm}</Typography>
            <Typography>{selectedValue?.ContractPhoneNotes}</Typography>
            <Typography>{selectedValue?.ContractPhoneNotesComm}</Typography>

        </AccordionDetails>
    </Accordion>;
}
