import { Typography, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import HotelIcon from '@mui/icons-material/Hotel';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { styled } from '@mui/material/styles';
import TextLanguage from '../data/TextLanguage.json';

const borderRadius = '20px';


const RegionalTabButton = styled(Button)(({ theme }) => ({

    fontWeight: 'bold',
    fontSize: '0.85rem',
    borderRadius: '0px',
    background: 'linear-gradient(to bottom, rgb(49, 80, 125), hsl(216, 44%, 25%))',
    paddingInline:'0.75rem',

    height: '3rem',
    transitionProperty: 'background',
    '& .Mui-disabled': {
        backgroundColor: `transparent`,
    },
    '&:hover, &.Mui-focusVisible': {
        transitionDuration: '255ms',
        transitionProperty: 'background',
        backgroundColor: `rgba(125,188,255,0.02)`,
        background: 'linear-gradient(to bottom, rgba(52,84,129,0.8), rgba(66, 151, 255, 0.78))',

        boxShadow: 'inset 0px 0px 18px -2px rgb(125,188,255)',
        zIndex: 50,
    },

    '& .Mui-focused': {
        backgroundColor: `white`,
    },
}));






export const MapBottomNavBar = ({
    regionalMode,
    setRegionalMode,
    setSelectedValue,
    lang
}) => {

    const searchActive = regionalMode === null;
    const hospitalActive = regionalMode === 'hospital';
    const hotelActive = regionalMode === 'hotel';


    return (<div className='BottomNavBar'>

            <div className='BottomNavBarSpacer' style={{
                borderTop: searchActive ? '2px solid #53d05391' : hospitalActive ? '2px solid #ff000091' : hotelActive ? '2px solid #00bfff9c' : '',
                borderRadius: searchActive ? `0px ${borderRadius} 0px 0px` : '0px'}} />
            <RegionalTabButton disableRipple sx={{borderTop: hospitalActive ? '2px solid #ff000091' : hotelActive ? '2px solid #00bfff9c' : '' }} className={searchActive ? 'activeRegionTab' : null} onClick={() => { setRegionalMode(null); }}>
                <SearchIcon sx={{ color: '#53d053' }} />
                <Typography variant='button' sx={{ color: 'var(--color-text)', paddingInline: '0.5rem', fontSize: '1rem' }}>
                {TextLanguage['Search'][lang]}
                </Typography>
            </RegionalTabButton>

            <div className='BottomNavBarSpacer' style={{
                borderTop: searchActive ? '2px solid #53d05391' : hospitalActive ? '2px solid #ff000091' : hotelActive ? '2px solid #00bfff9c' : '',
                borderRadius: searchActive ? `${borderRadius} 0px 0px 0px` : hospitalActive ? `0px ${borderRadius} 0px 0px` : null }} />
            <RegionalTabButton disableRipple sx={{borderTop: searchActive ? '2px solid #53d05391' : hotelActive ? '2px solid #00bfff9c' : '' }} className={hospitalActive ? 'activeRegionTab' : null} onClick={() => { setRegionalMode('hospital'); setSelectedValue(null); }}>
                <LocalHospitalIcon sx={{ color: 'red' }} />
                <Typography variant='button' sx={{ color: 'var(--color-text)', paddingInline: '0.5rem',fontSize: '1rem' }}>
                {TextLanguage['Hospital'][lang]}
                </Typography>
            </RegionalTabButton>

            <div className='BottomNavBarSpacer'
            style={{
                borderTop: searchActive ? '2px solid #53d05391' : hospitalActive ? '2px solid #ff000091' : hotelActive ? '2px solid #00bfff9c' : '',
                borderRadius: hospitalActive ? `${borderRadius} 0px 0px 0px` : hotelActive ? `0px ${borderRadius} 0px 0px` : '' }}/>
            <RegionalTabButton disableRipple sx={{borderTop: hospitalActive ? '2px solid #ff000091' : searchActive ? '2px solid #53d05391' : '' }} className={hotelActive ? 'activeRegionTab' : null} onClick={() => { setRegionalMode('hotel'); }}>
                <HotelIcon sx={{ color: 'deepskyblue' }} />
                <Typography variant='button' sx={{ color: 'var(--color-text)', paddingInline: '0.5rem',fontSize: '1rem' }}>
                {TextLanguage['Hotel'][lang]}
                </Typography>
            </RegionalTabButton>
            <div className='BottomNavBarSpacer' style={{
                borderTop: searchActive ? '2px solid #53d05391' : hospitalActive ? '2px solid #ff000091' : hotelActive ? '2px solid #00bfff9c' : '',
                borderRadius: hotelActive ? `${borderRadius} 0px 0px 0px` : '0px'}} />



    </div>);
}
