import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';


export const FormField = styled((props) => (<TextField className='FormField' {...props} />))
(({ theme, setheight = 60 }) => ({
    color: theme.palette.text.primary,
    background: theme.palette.gradient.d,
    borderRadius: 8,
    width: '100%',
    maxWidth:'100%',
    maxHeight: `${setheight}px`,
    '& .MuiFormControl-root': { height: '100%', maxHeight:`${setheight}px` },
    '& .MuiInputBase-root': { height: '100%', maxHeight:`${setheight}px` },

    '&:hover, &.Mui-focusVisible': {

        background: theme.palette.gradient.glow,
        boxShadow: `inset 0px 0px 18px -2px ${theme.palette.mainColor.Light}`,
        zIndex: 50,
    },
    '.MuiInputBase-formControl': {
        paddingInline: '0.25rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },

    '.MuiInputBase-inputMultiline': {
        paddingInline: '0.5rem 0.5rem !important',
        paddingBlock: '0.25rem !important'
    },
    '.MuiSelect-select': {
        border: 'none !important',
        paddingInline: '0.5rem 2.5rem !important',
        paddingBlock: '0.5rem 0.25rem'
    },
    '.MuiFormHelperText-root': {color: theme.palette.text.primary } ,
}));
