import React from 'react';
import { styled } from '@mui/system';
import SvgIcon from '@mui/material/SvgIcon';



const DentistLogo = styled(SvgIcon)(({ theme, sizeSetting }) => ({
  /* Add your custom styles here */
  width: sizeSetting, height: sizeSetting, padding: 0, margin: 0,
  opacity: 0.9,
  mixBlendMode: 'Normal',
  fill: 'currentColor',
  fillOpacity: 0.902174,
  stroke: 'currentColor',
  strokeWidth: 45.5826773,
  strokeLinecap: 'butt',
  strokeLinejoin: 'miter',
  strokeMiterlimit: 4,
  strokeDasharray: 'none',
  strokeDashoffset: 0,
  strokeOpacity: 0.24,
  stopColor: 'currentColor',
  stopOpacity: 1,
}));



function DentistIcon(props) {
  return (
    <DentistLogo>
      <svg

        viewBox="55 55 105 155"
        version="1.1"
        id="svg1231"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m 278.74023,245 c -57.99998,0 -80,53 -80,114 0,61 49.19914,90.92202 54,174 4.16463,72.0678 -27.07411,240.31635 33,240 46.94385,-0.24718 58.16046,-93.41187 59,-145 0.51712,-31.7763 -2.74076,-76.00001 52.12891,-77 54.86967,0.99999 51.61375,45.2237 52.13086,77 0.83955,51.58813 12.05615,144.75282 59,145 60.07412,0.31635 28.83538,-167.9322 33,-240 4.80087,-83.07798 54,-113 54,-174 0,-61 -22.00001,-114 -80,-114 -57.99999,0 -35.99999,77 -118.13033,77 -82.13034,0 -60.12945,-77 -118.12944,-77 z"
          transform="scale(0.26458333)"
        />
      </svg>
    </DentistLogo>
  );
}



export default DentistIcon;