
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import config from '../../../config';

export const useCreateUserMutation = ({  setUserData, setsnackbarStatus, setIsError   }) => {

  const queryClient = useQueryClient();

    return useMutation({
        mutationFn: ({ updaterData }) => {
            return axios.post(`${config.API_URL}/operator/createNewUser`, updaterData, {
                withCredentials: true,
            });
        },
        onSuccess: (data, variables) => {
            queryClient.invalidateQueries({ queryKey: ['userData'] , refetchType: 'active'}, {cancelRefetch: false});
            setIsError(!data?.data?.blobContainerStatus);
            setUserData(data?.data);
        },
        onError: (error, variables) => {
            const errorMessage = error?.response?.data?.error?.message || 'Error Creating Patient';
            setsnackbarStatus({ open: true, message: errorMessage, severity: 'error', duration: 8000 });

        }
    });
}
