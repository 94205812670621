import { useState } from 'react';
import {
    Menu,
    MenuItem,
    Typography
} from '@mui/material';
import TextLanguage from './data/TextLanguage.json';
import { useNavigate } from 'react-router-dom';
import { useLogoutMutation } from '../components/Login/useLogoutMutation';

export const PageMenu = ({ lang, anchorEl, setAnchorEl, currentPage }) => {

    const navigate = useNavigate();
    const isMenuOpen = Boolean(anchorEl);
    const logoutMutation = useLogoutMutation();

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (<Menu
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        id={'routerMenu'}
        keepMounted
        transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{ '& .MuiBackdrop-root': { background: 'transparent', backdropFilter: 'none' } }}
    >
        {currentPage !== 'HotelSearch' &&
            <MenuItem onClick={() => {
                setAnchorEl(null);
                navigate('/operator');
            }}>
                <Typography>{TextLanguage["HotelSearchPageName"][lang]}</Typography>
            </MenuItem>
        }

        {currentPage !== 'DataEditor' &&
            <MenuItem onClick={() => {
                setAnchorEl(null);
                navigate('/list');
            }}>
                <Typography>{TextLanguage["DataEditor"][lang]}</Typography>
            </MenuItem>
        }

        {currentPage !== 'Login' &&
            <MenuItem  onClick={() => {
                setAnchorEl(null);
                logoutMutation.mutate();
            }}>
               <Typography variant='button' sx={{color:'red', fontWeight:'bold'}}>{TextLanguage["Logout"][lang]}</Typography>
            </MenuItem>
        }

    </Menu>);
}


/*
        currentPage !== 'DataDisplay' &&
            <MenuItem onClick={() => {
                setAnchorEl(null);
                navigate('/chart');

            }}>
                {TextLanguage["DataPageName"][lang]}
            </MenuItem>
*/

