

/*

    1. Data Processing: Fix hotel info list and update (Merge list of contracted hosp with main hosp list--figure out what to do with missing hospitals)
    13. Add formatting for Phone,Fax etc.
    Missing Hospitals (Flame Medical(contract for hosp but no record on our end (is it ignored?)), May Day Marmaris, Letoon, Alaiye MC, Marina Clinic)
    Missing Doctors (Ismet Yildiz, Unal bilgin, Mustafa Sarisli, Taner karaman{connected to like 6 hospitals})


*/
import { useState, useEffect, useRef } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/tr';

import LiveSearch from '../components/OperatorApp/LiveSearch';
import MapComponent from '../components/OperatorApp/MapComponent';
import {

    CircularProgress,
    MenuItem,
    Typography,
    useTheme
} from '@mui/material';


import TextLanguage from '../components/data/TextLanguage.json';
import useLocalStorage from '../hooks/useLocalStorage';


import { useValidateQuery } from '../hooks/useValidateQuery';
import { useHotelQuery } from '../components/OperatorApp/hooks/useHotelQuery';
import { useHospitalQuery } from '../components/OperatorApp/hooks/useHospitalQuery';
import { useHospitalUserListQuery } from '../components/OperatorApp/hooks/useHospitalUserListQuery';

import { useRegionQuery } from '../components/OperatorApp/hooks/useRegionQuery';


import { useMapboxKeyQuery } from '../components/OperatorApp/hooks/useMapboxKeyQuery';
import { HospitalAccordion } from '../components/OperatorApp/HospitalAccordion';
import { HotelUpdateForm } from '../components/OperatorApp/HotelUpdateForm';
import { CreatePatientForm } from '../components/OperatorApp/CreatePatientForm';
import { FilterButtonGroup } from '../components/OperatorApp/FilterButtonGroup';
import { HotelAccordion } from '../components/OperatorApp/HotelAccordion';
import { NewPatientButton } from '../components/StyledComponents/NewPatientButton';
import { AppBar } from '../components/AppBar';
import { useMapboxDirectionsQuery } from '../components/OperatorApp/hooks/useMapboxDirectionsQuery';


import { ContactModal } from '../components/OperatorApp/ContactModal';
import { HospitalUpdateForm } from '../components/OperatorApp/HospitalUpdateForm';
import { FormField } from '../components/StyledComponents/FormField';

import { CustomSnackbar } from '../components/StyledComponents/CustomSnackbar';



import { HotelAccordionRegional } from '../components/OperatorApp/HotelAccordionRegional';
import { MapBottomNavBar } from '../components/OperatorApp/MapBottomNavBar';
import { useInsuranceCompanyListQuery } from '../hooks/useInsuranceCompanyListQuery';
import { useRefreshQuery } from '../components/Login/useRefreshQuery';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';




export function OperatorApp() {

    const queryClient = useQueryClient();

    const theme = useTheme();

    const currentPage = 'HotelSearch';
    //const modalFormState = useRef(null);

    const [lng, setLng] = useState(30.648559672524495);
    const [lat, setLat] = useState(36.86892032811018);
    // Map Hooks
    const [selectedValue, setSelectedValue] = useState(null); // Hotel Search Result

    const [expanded, setExpanded] = useState(-1); // Accordion Hooks
    const [expandAll, setexpandAll] = useState(false);
    const [accordpanelID, setaccordpanelID] = useState(0); // Panel after x ms for flyto
    const [accordpanelIDInstant, setaccordpanelIDInstant] = useState(0); // Panel after hover to change bg

    const [closestHospitalData, setClosestHospitalData] = useState();  // Pull hosp data for Regional group
    const [filterByRegion, setfilterByRegion] = useState(true);
    const [modalFormType, setmodalFormType] = useState('hotel'); // Modal Form Type

    const app = useRef();
    const contactGlow = useRef();
    const accordionListRef = useRef();

    const [lang, setlang] = useLocalStorage('lang', 'trTR');

    const [hotelData, setHotelData] = useState([]);

    const [formats, setFormats] = useState(() => ['Hospital', 'Clinic', 'Dentist', 'Doctor', 'MedCenter']); // Toggle Hosp Search Buttons

    const [selectedRegion, setSelectedRegion] = useState('All');
    const [regionalMode, setRegionalMode] = useState(null);
    const [regionalHospitalList, setRegionalHospitalList] = useState([]);
    const [regionalHotelList, setRegionalHotelList] = useState([]);

    const searchModeActive = regionalMode === null;
    const hospitalRegionalModeActive = regionalMode === 'hospital';
    const hotelRegionalModeActive = regionalMode === 'hotel';


    const insCoListQuery = useInsuranceCompanyListQuery();


    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
    const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

    useEffect(() => {

        const handleResize = () => {
            setViewportWidth(window.innerWidth);
            setViewportHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, [])



    const [snackbarStatus, setsnackbarStatus] = useState({
        open: false,
        message: '',
        severity: 'success'

    });


    const handleRegionSelection = (event) => {
        setSelectedValue(null);
        setSelectedRegion(event.target.value);
        //setRegionalMode(true);
    }

    useEffect(() => {
        if (searchModeActive) {

            if (filterByRegion && selectedValue) {
                setSelectedRegion(selectedValue?.Region);
            } else if (!filterByRegion && selectedValue) {
                setSelectedRegion('All');
            }
        }
    }, [filterByRegion])



    useEffect(() => {
        if (selectedValue) {
            setSelectedRegion(selectedValue?.Region)
            setRegionalMode(null);
        } else {
            accordionListRef.current = null;
        }
    }, [selectedValue])



    // Query Hooks

    const validateQuery = useValidateQuery();
    const refreshQuery = useRefreshQuery();
    const confirmedUserID = validateQuery.data?.data?.userID;

    const navigate = useNavigate();

    useEffect(() => {

        if (refreshQuery.isError && refreshQuery.failureCount >= 3 ) {
            console.log('Refresh Token Expired');
            queryClient.clear()
            navigate('/');
        } else if (refreshQuery.isError && refreshQuery.error?.response?.status === 403) {
            console.log('Refresh Token Expired');
            queryClient.clear()
            navigate('/');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshQuery, refreshQuery.isError, refreshQuery.failureCount, refreshQuery.error])




    const hotelDataQuery = useHotelQuery();

    useEffect(() => {
        if (hotelDataQuery.isSuccess) {
            const sortedResults = [...hotelDataQuery?.data?.data].sort((a, b) =>
                a?.Area.localeCompare(b?.Area)
            );
            setHotelData(sortedResults);
        }
    }, [hotelDataQuery.data, hotelDataQuery.isSuccess])


    const hospDataQuery = useHospitalQuery();
    const hospUserDataQuery = useHospitalUserListQuery();



    const MapboxAPIKeyQuery = useMapboxKeyQuery();


    const directionsQuery = useMapboxDirectionsQuery({ hotel: selectedValue, selectedValue: selectedValue });

    useEffect(() => {
        if (directionsQuery.isSuccess) {
            setClosestHospitalData(directionsQuery?.data?.data);

        }
    }, [directionsQuery.data, directionsQuery.isSuccess])

    const regionDataQuery = useRegionQuery();

    // Modal Controls


    useEffect(() => {
        if (!searchModeActive) {
            hospitalRegionalModeActive && setRegionalHospitalList(
                selectedRegion === 'All' ? hospDataQuery.data?.data
                    .map((hospital, index) => {
                        return {
                            ...hospital,
                            index: index
                        }
                    })
                    :
                    hospDataQuery.data?.data?.filter((hospital) => { return (hospital?.Region?.includes(selectedRegion)) })
                        .map((hospital, index) => {
                            return {
                                ...hospital,
                                index: index
                            }
                        })

            )
            hotelRegionalModeActive && setRegionalHotelList(
                selectedRegion === 'All' ? hotelDataQuery.data?.data
                    .map((hospital, index) => {
                        return {
                            ...hospital,
                            index: index
                        }
                    })
                    :
                    hotelDataQuery.data?.data?.filter((hotel) => { return (hotel?.Region === selectedRegion) })
                        .map((hotel, index) => {
                            return {
                                ...hotel,
                                index: index
                            }
                        })
            )
        }



    }, [regionalMode, selectedRegion, hotelDataQuery.data, hospDataQuery.data])



    const [FormModalOpen, setFormModalOpen] = useState(false);
    const handleEditFormOpen = () => {
        setmodalFormType('hotel');
        setFormModalOpen(true);
    }
    const handlePatientFormOpen = () => {
        setmodalFormType('patient');
        setFormModalOpen(true);
    };

    // Accordion Controls

    useEffect(() => {
        setExpanded((selectedValue &&
            (selectedValue.NameComm || selectedValue.AreaComm || selectedValue.PhoneComm ||
                selectedValue.ReferenceComm || selectedValue.ContractComm || selectedValue.ContractPhoneNotesComm)) ? -1 : 0)
    }, [selectedValue])

    const [openModal, setOpenModal] = useState(-1);

    const handleAccordChange = (panel) => (event, newExpanded) => {

        setExpanded(newExpanded ? panel : false); //Accordion expand function hook
        setaccordpanelID(panel);
        setaccordpanelIDInstant(panel);
    };


    // Event Listener for hovering on markers

    const [durationRange, setDurationRange] = useState([0, 60]);

    useEffect(() => {

        const handleMouseOver = (event) => {

            // Handle mouseover on a marker
            // Access the specific marker information and show/hide the popup

            if (event.target.classList.contains('custom-marker-markhosp')) {
                const markerId = event.target.dataset.markerId;
                const popupOpenModalFunc = (clickedMarkerId) => {
                    setOpenModal(clickedMarkerId);
                    // console.log(openModal);
                };
                document.getElementById(`popupButton-${markerId}`).addEventListener('click', () => popupOpenModalFunc(markerId));
            }
        };

        //app.current.addEventListener('mouseover', handleMouseOver);


        if (closestHospitalData) {
            const tempRange = closestHospitalData.reduce((acc, curr) => {
                // go through the array and reduce to two values with the lowest and highest durations
                const currDuration = curr?.directionAPI?.routes[0]?.duration;

                return [
                    Math.min(acc[0], currDuration),
                    Math.max(acc[1], currDuration),
                ];

            }, [Infinity, -Infinity])
            // console.log(closestHospitalData);

            setDurationRange([Math.max(tempRange[0], 20), tempRange[1]])
        }


        return () => {

            if (app.current) {
                app.current.removeEventListener('mouseover', handleMouseOver);
            }
        }


    }, [closestHospitalData, openModal]);


    const [mapboxAPIKey, setMapboxAPIKey] = useState('');
    useEffect(() => {
        if(MapboxAPIKeyQuery.isSuccess && regionDataQuery.isSuccess){
            setMapboxAPIKey(MapboxAPIKeyQuery.data?.data?.mapboxAPIKey)
        }
    }, [MapboxAPIKeyQuery, MapboxAPIKeyQuery.isSuccess, MapboxAPIKeyQuery.data, regionDataQuery, regionDataQuery.isSuccess, regionDataQuery.data])



    /*
        modalFormState.current = (modalFormType === 'hotel') ?
            <HotelUpdateForm
                setsnackbarStatus={setsnackbarStatus}
                hospitalData={hospitalData}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                setFormModalOpen={setFormModalOpen}

            />
            :
            (modalFormType === 'patient') ?
                <CreatePatientForm
                    selectedValue={selectedValue}
                    setFormModalOpen={setFormModalOpen}
                    setsnackbarStatus={setsnackbarStatus}
                    lang={lang}
                />
                :
                null;
                            <Modal
                                open={FormModalOpen}
                                onClose={() => { setFormModalOpen(false); }}
                                id={`HotelEditFormModal`}>
                                <Box sx={[ModalStyle, { padding: '3rem' }]}>
                                    {modalFormState.current}
                                </Box>
                            </Modal>
    */

    const [openContactModal, setOpenContactModal] = useState(-1);


    accordionListRef.current = (hospitalRegionalModeActive && !!regionalHospitalList) ?
        regionalHospitalList
            .filter((data) => { return (formats.some(entry => entry.includes(data.Type))) }) // Filter by Type
            .map((data, index) => {
                return (
                    <HospitalAccordion
                        data={data}
                        lang={lang}
                        expanded={expanded}
                        expandAll={expandAll}
                        selectedValue={selectedValue}
                        handleAccordChange={handleAccordChange}
                        setOpenModal={setOpenModal}
                        setaccordpanelIDInstant={setaccordpanelIDInstant}
                        setaccordpanelID={setaccordpanelID}
                        accordpanelIDInstant={accordpanelIDInstant}
                        contactGlow={contactGlow}
                        openModal={openModal}
                        durationRange={durationRange}
                        regionalMode={regionalMode}
                        openContactModal={openContactModal}
                        setOpenContactModal={setOpenContactModal}

                    />)
            })

        : (hotelRegionalModeActive && !!regionalHotelList) ?
            regionalHotelList
                .slice(0, 75)
                .map((data, index) => {
                    return (
                        <HotelAccordionRegional
                            key={`hotel-accord-regional-${index}`}
                            data={data}
                            lang={lang}
                            expanded={expanded}
                            expandAll={expandAll}
                            selectedValue={selectedValue}
                            handleAccordChange={handleAccordChange}
                            setOpenModal={setOpenModal}
                            setaccordpanelIDInstant={setaccordpanelIDInstant}
                            setaccordpanelID={setaccordpanelID}
                            accordpanelIDInstant={accordpanelIDInstant}
                            contactGlow={contactGlow}
                            openModal={openModal}
                            durationRange={durationRange}
                            openContactModal={openContactModal}
                            setOpenContactModal={setOpenContactModal}
                        />)
                })


            :
            (!regionalMode && selectedValue && !!closestHospitalData) && closestHospitalData
                .filter((data) => { return (formats.some(entry => entry.includes(data.Type))) }) // Filter by Type
                .filter((data) => { return (filterByRegion ? (data.regionFilter) : true) }) // Filter by Region
                .map((data, index) => {

                    return (
                        <HospitalAccordion
                            key={`hosp-accord-regional-${index}`}
                            data={data}
                            lang={lang}
                            expanded={expanded}
                            expandAll={expandAll}
                            selectedValue={selectedValue}
                            handleAccordChange={handleAccordChange}
                            setOpenModal={setOpenModal}
                            setaccordpanelIDInstant={setaccordpanelIDInstant}
                            setaccordpanelID={setaccordpanelID}
                            accordpanelIDInstant={accordpanelIDInstant}
                            contactGlow={contactGlow}
                            openModal={openModal}
                            durationRange={durationRange}
                            openContactModal={openContactModal}
                            setOpenContactModal={setOpenContactModal}
                        />
                    )
                });

    // Top Bar Height = 78 (LiveSearch) + 60 (AppBar) style = {{height:(height - 78- 60), maxHeight:(height - 78- 60)}}

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">

            <div ref={app} className="HospSearchMain">
                <CustomSnackbar
                    snackbarStatus={snackbarStatus}
                    setsnackbarStatus={setsnackbarStatus}
                />

                <AppBar
                    lang={lang}
                    setlang={setlang}
                    currentPage={currentPage}
                />

                <div className='top-search-container'>
                    {
                        regionalMode ?
                            <div style={{ padding: '0.25rem 1rem', width: '20%' }}>

                                <FormField
                                    id="select-region"
                                    select
                                    value={selectedRegion}
                                    onChange={handleRegionSelection}
                                    sx={{
                                        margin: '0.25rem 0',
                                        background: theme.palette.gradient.c
                                    }}
                                >
                                    <MenuItem value={'All'}>All</MenuItem>
                                    {regionDataQuery.data?.data?.map((option) => (
                                        <MenuItem key={`Cont-Hosp-Opt-${option?.groupID}`} value={option?.Region}>
                                            {option?.Region}
                                        </MenuItem>
                                    ))}
                                </FormField>
                            </div>
                            :
                            <>

                                <LiveSearch
                                    selectedValue={selectedValue}
                                    setLng={setLng} setLat={setLat}
                                    setSelectedValue={setSelectedValue}
                                    lang={lang}
                                    viewportHeight={viewportHeight}

                                />
                                {
                                    selectedValue ?
                                        <div className='RegionDisplayBox' >
                                            <Typography sx={{fontSize:'0.9rem', fontWeight:600}}>
                                                {selectedValue?.Region}
                                            </Typography>
                                        </div>
                                        : null
                                }

                            </>

                    }

                    {searchModeActive &&
                        <NewPatientButton
                            onClick={handlePatientFormOpen}
                        >
                            {TextLanguage["NewPatient"][lang]}
                        </NewPatientButton>

                    }

                </div>

                <div className='patient-container'  >
                    <div className='left'  >
                        <div style={{ maxWidth: '100%', display: 'flex', flexDirection: 'column' }}>
                            {!hotelRegionalModeActive && <FilterButtonGroup
                                lang={lang}
                                formats={formats}
                                setFormats={setFormats}
                                filterByRegion={filterByRegion}
                                setfilterByRegion={setfilterByRegion}
                                expandAll={expandAll}
                                setexpandAll={setexpandAll}
                                regionalMode={regionalMode}
                                disabled={hotelRegionalModeActive ? true : false}
                                viewportWidth={viewportWidth}
                                viewportHeight={viewportHeight}
                            />}
                            {!!regionalMode ? null :
                                <HotelAccordion
                                    handleAccordChange={handleAccordChange}
                                    expanded={expanded}
                                    expandAll={expandAll}
                                    lang={lang}
                                    handleEditFormOpen={handleEditFormOpen}
                                    selectedValue={selectedValue}
                                />
                            }
                        </div>
                        <div  className='HospListBox'>
                            {accordionListRef.current}
                        </div>

                        <MapBottomNavBar
                            regionalMode={regionalMode}
                            setRegionalMode={setRegionalMode}
                            setSelectedValue={setSelectedValue}
                            lang={lang}
                        />

                    </div>
                    <div>



                        {regionalMode ?
                            <HospitalUpdateForm
                                setsnackbarStatus={setsnackbarStatus}
                                data={regionalHospitalList}
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                regionDataQuery={regionDataQuery}
                                lang={lang}
                            />
                            :
                            <ContactModal
                                openModal={openModal}
                                setOpenModal={setOpenModal}
                                data={closestHospitalData}
                                lang={lang}
                            />
                        }
                    </div>
                    <div className='right' style={{ width: '100%', position: 'relative' }}>

                        {

                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: FormModalOpen ? '100%' : '0%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',

                                zIndex: 10,
                                transition: 'all 0.25s ease-out'
                            }}>
                                {
                                    (FormModalOpen && (modalFormType === 'patient')) && <CreatePatientForm
                                        selectedValue={selectedValue}
                                        setFormModalOpen={setFormModalOpen}
                                        setsnackbarStatus={setsnackbarStatus}
                                        lang={lang}
                                        hotelData={hotelData}
                                    />
                                }
                                {
                                    (FormModalOpen && (modalFormType === 'hotel')) && <HotelUpdateForm
                                        setsnackbarStatus={setsnackbarStatus}
                                        selectedValue={selectedValue}
                                        setSelectedValue={setSelectedValue}
                                        setFormModalOpen={setFormModalOpen}
                                        lang={lang}
                                    />



                                }
                            </div>







                        }

                        {

                            (!!mapboxAPIKey) ?

                                <MapComponent id='mapComp' className='mapContainer' sx={{ width: '66%', height: '100%' }}
                                    lng={lng} lat={lat}
                                    setOpenModal={setOpenModal}
                                    durationRange={durationRange}
                                    selectedValue={selectedValue}
                                    accordpanelID={accordpanelID}
                                    setaccordpanelID={setaccordpanelID}
                                    closestHospitalData={closestHospitalData}
                                    filterByRegion={filterByRegion}
                                    formats={formats}
                                    mapboxAPIKey={mapboxAPIKey}
                                    regionDataQuery={regionDataQuery}
                                    regionalHospitalList={regionalHospitalList}
                                    regionalHotelList={regionalHotelList}
                                    regionalMode={regionalMode}
                                    selectedRegion={selectedRegion}


                                />
                                :
                                <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'black' }}>
                                    <CircularProgress color="success" size='4rem' />
                                </div>


                        }

                    </div>
                </div>

            </div>

        </LocalizationProvider>
    )




}









