
import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

import { interpolateColor } from '../../functions/interpolateColor';
import { createHotelMarker } from '../MarkerElements/createHotelMarker';
import { createHospitalMarker } from '../MarkerElements/markerHospitalElement';
import { createMedCenterMarker } from '../MarkerElements/markerMedCenterElement';
import { createCustomMarkerImage } from '../MarkerElements/createCustomMarkerImage';
import { interpolateWarm, interpolateCividis, interpolatePlasma, interpolatePiYG, interpolateCool, interpolateBlues } from 'd3-scale-chromatic';
import { scaleSequential } from 'd3-scale';
import { rgb } from 'd3-color';
//import markerLocation from '../../img/markerLocation.png';

const colorScale = scaleSequential(t => {
    const color = interpolatePlasma(t);
    return rgb(color).formatHex();
}).domain([1, 0]);



function lerp(start, end, t) {
    return start * (1 - t) + end * t;
}

const totalSteps = 25;
const animSpeed = 0.00125;
const dashLength = 20;
const greenColor = 'hsl(98, 100%, 50%)';
const darkGreenColor = 'hsl(98, 79%, 21%)';
const redColor = 'hsl(0, 100%, 50%)';
const darkRedColor = 'hsl(0, 79%, 21%)';

// const generateSmoothDashArrayForStep = (totalSteps, currentStep, dashLength) => {
//     const t = Math.min(Math.max(currentStep / (totalSteps - 1), 0), 1)
//     if (t < 0.5) {

//         const dashStartLength = lerp(0, dashLength, 2 * t);
//         const dashEndLength = dashLength - dashStartLength;
//         //  [Divided Dash Left, Constant Gap, Divided Dash Right]
//         return [Math.round(dashStartLength * 1000) / 1000, dashLength, Math.round(dashEndLength * 1000) / 1000]; // [0.5, 1 , 0.5] -> [0]
//     } else {
//         const gapStartLength = lerp(0, dashLength, 2 * t) - dashLength;
//         const gapEndLength = dashLength - gapStartLength;
//         //  [Empty Dash, Divided Gap Left, Constant Dash, Divided Gap Right]
//         return [0, Math.round(gapStartLength * 1000) / 1000, dashLength, Math.round(gapEndLength * 1000) / 1000];
//     }
// };



const MapComponent = ({ lng, lat, selectedValue, accordpanelID, setaccordpanelID,
    closestHospitalData, durationRange,
    filterByRegion, formats, setOpenModal,
    mapboxAPIKey, regionDataQuery,
    regionalHospitalList,
    regionalHotelList,
    regionalMode, selectedRegion,



}) => {

    const [geojsonCoordinates, setGeojsonCoordinates] = useState([]);

    useEffect(() => {
        if (map.current && durationRange && closestHospitalData && closestHospitalData.length > 0) {
            const EndPointColor = `${interpolateColor(closestHospitalData[accordpanelID]?.directionAPI?.routes[0]?.duration, durationRange[0], durationRange[1], greenColor, redColor)}`
            map.current.setPaintProperty('routeLayerBackground', 'line-gradient', [
                'interpolate',
                ['linear'],
                ['line-progress'],
                0,
                greenColor,
                1,
                EndPointColor
            ]);
            map.current.setPaintProperty('routeLayerAnimated', 'line-gradient', [
                'interpolate',
                ['linear'],
                ['line-progress'],
                0,
                greenColor,
                1,
                EndPointColor
            ]);
        };

    }, [durationRange, accordpanelID, closestHospitalData])






    // Accordion Hook
    const flyToDuration = 1255;

    const mapContainer = useRef(null);
    const map = useRef(null);
    const bounds = new mapboxgl.LngLatBounds();
    const [zoom, setZoom] = useState(13);


    const marker = useRef(null); // Hotel Marker
    const hospMarkers = useRef([[], []]);  // Array to store markers
    const popupHosp = useRef(null);

    const defaultZoomUpper = 13.5;
    const defaultZoomLower = 12;

    // Function to add markers based on locations

    // Markers are created by looping over the list of the closest hospitals, identifying the type of marker and instantiating a marker for each element
    // (add missing marker types!)
    // as each marker is added the bounds are reset and the references are placed in a double array hospMarkers = [[markerRefArray],[markerIndexArray]]
    // the ref array holds useRef and the index array holds the original ordering index by distance so that they can be
    // referenced properly even after filtering from the closesthospitals
    const wipeMarkers = () => {
        hospMarkers.current[0].forEach(marker => marker.remove());
        hospMarkers.current = [[], []];
    };

    const addMarkers = (HospHotelArray) => {
        if (HospHotelArray === null || HospHotelArray.length === 0) return;
        hospMarkers.current[0].forEach(marker => marker.remove());  // Remove previous markers from the map

        hospMarkers.current = [[], []];    // Clear the markers array
        let markerRefArray = [];
        let markerIndexArray = [];

        HospHotelArray.forEach(data => {   // Add new markers for each location
            if (data === null || data?.Lat === 0 || data?.Lng === 0 || !data?.Lat || !data?.Lng) return;
            if (data.Type === "Hospital") {

                const markerhospit = new mapboxgl.Marker(createHospitalMarker(data.index, 'Hospital')) //Hospital Marker
                    .setLngLat([data.Lng, data.Lat])
                    .addTo(map.current);

                markerRefArray.push(markerhospit);
                markerIndexArray.push(data.index);

            } else if (data.Type === 'MedCenter') {
                const markerclinic = new mapboxgl.Marker(createHospitalMarker(data.index, 'MedCenter')) //Clinic marker -- Create new marker types for each later
                    .setLngLat([data.Lng, data.Lat])
                    .addTo(map.current);
                markerRefArray.push(markerclinic);
                markerIndexArray.push(data.index);
            }
            else if (data.Type === 'Dentist') {
                const markerclinic = new mapboxgl.Marker(createHospitalMarker(data.index, 'Dentist' )) //Clinic marker
                    .setLngLat([data.Lng, data.Lat])
                    .addTo(map.current);
                markerRefArray.push(markerclinic);
                markerIndexArray.push(data.index);
            }
            else if (data.Type === 'Doctor') {
                const markerclinic = new mapboxgl.Marker(createHospitalMarker(data.index, 'Doctor')) //Clinic marker
                    .setLngLat([data.Lng, data.Lat])
                    .addTo(map.current);
                markerRefArray.push(markerclinic);
                markerIndexArray.push(data.index);
            } else if (data.Type === 'Clinic') {
                const markerclinic = new mapboxgl.Marker(createHospitalMarker(data.index, 'Clinic')) //Clinic marker
                    .setLngLat([data.Lng, data.Lat])
                    .addTo(map.current);
                markerRefArray.push(markerclinic);
                markerIndexArray.push(data.index);
            } else {

                const markerHotel = new mapboxgl.Marker(createHotelMarker(data.index))
                    .setLngLat([data.Lng, data.Lat])
                    .addTo(map.current);
                markerRefArray.push(markerHotel);
                markerIndexArray.push(data.index);

            }

            bounds.extend([data.Lng, data.Lat]);
        }); // ForEach ends here

        hospMarkers.current[0] = markerRefArray;
        hospMarkers.current[1] = markerIndexArray;

        map.current.fitBounds(bounds, { padding: 50 });
        let currentZoom = map.current.getZoom();
        let zoomValue = Math.min(Math.max(currentZoom, defaultZoomLower), (defaultZoomUpper));
        setZoom(zoomValue);

    };


    useEffect(() => {

        mapboxgl.accessToken = mapboxAPIKey;

        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            //style: 'mapbox://styles/mapbox/navigation-night-v1',
            //style: 'mapbox://styles/mapbox/streets-v12',
            //style: 'mapbox://styles/mapbox/satellite-streets-v12',
            style: 'mapbox://styles/mapbox/dark-v11',
            //maxBounds: [[25.9174683253332, 33.914773556029964], [44.206132542282496, 42.538609767504205]],
            center: [lng, lat],
            zoom: zoom,
            failIfMajorPerformanceCaveat: false,
        });
        if (map.current && regionDataQuery.isSuccess && !!regionDataQuery?.data?.data) {
            map.current.on('load', function () {


                regionDataQuery?.data?.data?.forEach((area) => {

                    const randomColor = colorScale(Math.random())
                    if (area?.geometry) {

                        map.current.addSource(area?.Region, {
                            'type': 'geojson',
                            'data': {
                                'type': 'Feature',
                                'geometry': area?.geometry
                            }
                        });

                        // Add a new layer to visualize the polygon.
                        map.current.addLayer({
                            'id': area.Region,
                            'type': 'fill',
                            'source': area.Region, // reference the data source
                            'layout': {},
                            'paint': {
                                'fill-color': randomColor, // blue color fill
                                'fill-opacity': 0.05
                            }
                        });
                        // Add a black outline around the polygon.
                        map.current.addLayer({
                            'id': `${area.Region}-border`,
                            'type': 'line',
                            'source': area.Region,
                            'layout': {},
                            'paint': {
                                'line-color': randomColor,
                                'line-width': 3,
                                'line-opacity': 0.15
                            }
                        });


                    }


                })












                map.current.addSource('route',
                    {
                        type: 'geojson',
                        lineMetrics: true,
                        data: {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'LineString',
                                coordinates: geojsonCoordinates
                            }
                        }
                    }
                );




                map.current.loadImage(
                    createCustomMarkerImage('hsla(9, 97%, 43%, 0.75)', 25),
                    (error, image) => {
                        if (error) throw error;
                        map.current.addImage('hospital-marker', image);
                    });

                map.current.loadImage(
                    createCustomMarkerImage('hsl(0, 100%, 55%)', 35),
                    (error, image) => {
                        if (error) throw error;
                        map.current.addImage('hospital-selected-marker', image);
                    });

                map.current.loadImage(
                    createCustomMarkerImage('hsl(206, 96%, 54%)', 35),
                    (error, image) => {
                        if (error) throw error;
                        map.current.addImage('hotel-selected-marker', image);
                    });

                map.current.loadImage(
                    createCustomMarkerImage('hsla(215, 61%, 32%, 0.75)', 25),
                    (error, image) => {
                        if (error) throw error;
                        map.current.addImage('hotel-marker', image);
                    });


                map.current.addSource('markerPoints',
                    {
                        'type': 'geojson',
                        'data': {
                            'type': 'FeatureCollection',
                            'features': [
                                {

                                }
                            ]
                        }

                    }
                );
                map.current.addLayer({
                    'id': 'markerPoints',
                    'type': 'symbol',
                    'source': 'markerPoints',
                    'layout': {
                        'icon-image': [
                            'case',
                            ['==', ['get', 'marker-type'], 'hospitalNormal'],
                            'hospital-marker',
                            ['==', ['get', 'marker-type'], 'hospitalSelected'],
                            'hospital-selected-marker',
                            ['==', ['get', 'marker-type'], 'hotelNormal'],
                            'hotel-marker',
                            ['==', ['get', 'marker-type'], 'hotelSelected'],
                            'hotel-selected-marker',
                            'hospital-marker'
                        ],
                        // get the title name from the source's "title" property
                        'text-field': ['get', 'text-content'],
                        'text-font': [
                            'Open Sans Semibold',
                            'Arial Unicode MS Bold'
                        ],
                        'text-offset': [0, 1.75],
                        'text-anchor': 'top',
                        'symbol-sort-key': ['get', 'sort-order'],
                        'text-optional': true
                    },
                    'paint': {
                        'text-color': 'white',
                        'text-halo-color': ['get', 'color'],
                        'text-halo-width': 0.5

                    },


                });


                /* Simple Marker Image
                map.current.loadImage(
                    markerLocation,
                (error, image) => {
                    if (error) throw error;
                    map.current.addImage('dragPoint-marker', image);
                });
                */


                // addRegionPolygons(map, regionData);




                map.current.addLayer({
                    type: 'line',
                    source: 'route',
                    id: 'routeLayerBackground',
                    paint: {
                        'line-color': '#2b2b2b',
                        'line-width': 14,
                        'line-opacity': 0.25,
                        'line-blur': 8,
                        'line-gradient': [
                            'interpolate',
                            ['linear'],
                            ['line-progress'],
                            0,
                            darkGreenColor,
                            1,
                            darkRedColor
                        ]
                    }
                });

                map.current.addLayer({
                    'id': 'routeLayerAnimated',
                    'type': 'line',
                    'source': 'route',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round'
                    },
                    'paint': {
                        'line-color': '#c1ff4d',
                        'line-width': 6,
                        'line-opacity': 1,
                        // 'line-dasharray': generateSmoothDashArrayForStep(totalSteps, 0, dashLength),
                        // 'line-dasharray': [0, 0], // [0, Math.round(gapStartLength * 1000) / 1000, dashLength, Math.round(gapEndLength * 1000) / 1000]
                        'line-blur': 4,
                        'line-gradient': [
                            'interpolate',
                            ['linear'],
                            ['line-progress'],
                            0,
                            greenColor,
                            1,
                            redColor
                        ]
                    }
                });

/*
                let step = 0;

                function animateDashArray(timestamp) {
                    // Update line-dasharray using the next value in dashArraySequence. The
                    // divisor in the expression `timestamp / 50` controls the animation speed.
                    const newStep = parseInt(
                        (timestamp * animSpeed) % totalSteps
                    );


                    if (newStep !== step) {

                        map.current.setPaintProperty(
                            'routeLayerAnimated',
                            'line-dasharray',
                            generateSmoothDashArrayForStep(totalSteps, step, dashLength)
                        );
                        step = newStep;
                    }

                    // Request the next frame of the animation.
                    requestAnimationFrame(animateDashArray);
                }
                */
                // start the animation
                //animateDashArray(0);



                map.current.resize();

            });
        }

        return () => {
            if (marker.current) {
                marker.current.remove();
            }
        }
    }, [regionDataQuery.data, regionDataQuery.isSuccess]);

    useEffect(() => {
        if (map.current && geojsonCoordinates?.length > 1) {
            map.current.getSource('route')?.setData({
                type: 'Feature',
                properties: {},
                geometry: {
                    type: 'LineString',
                    coordinates: geojsonCoordinates
                }
            });
        }


    }, [geojsonCoordinates]);



    useEffect(() => {

        const handleMouseOver = (event) => {

            if (event.target.classList.contains('custom-marker-markhosp')) {
                const markerId = parseInt(event.target.dataset.markerId);
                /*

                                if (popupHosp.current) {
                                    popupHosp.current.remove();
                                }

                                let i = 0;

                                for (i = 0; i < hospMarkers.current[1].length; i++) {
                                    if (hospMarkers.current[1][i] == markerId) { break }
                                }

                                popupHosp.current = new mapboxgl.Popup({
                                    offset: 20, closeButton: false,
                                    closeOnClick: false, className: "mappopupHosp"
                                })
                                    .setHTML(`

                          <button id="popupButton-${markerId}" class="popup-Button">

                          ${
                            regionalMode ?
                                ((markerId !== null) && regionalHospitalList) ? regionalHospitalList[markerId].Name : ''
                                :
                            ((markerId !== null) && closestHospitalData) ? closestHospitalData[markerId].Name : ''
                        }

                          </button>

                          `);

                                hospMarkers.current[0][i].setPopup(popupHosp.current).addTo(map.current).togglePopup();
                */

                setaccordpanelID(markerId);


                // Handle mouseover on a marker
                // Access the specific marker information and show/hide the popup
            }
        };


        mapContainer.current.addEventListener('mouseover', handleMouseOver);

        return () => {

            if (mapContainer.current) {
                mapContainer.current.removeEventListener('mouseover', handleMouseOver);
            }
        }

    }, [closestHospitalData]);



    useEffect(() => {
        if (!(map.current && hospMarkers.current)) { return }
        if (regionalMode === 'hospital') {

            map.current.flyTo({
                center: [regionalHospitalList[accordpanelID].Lng,
                regionalHospitalList[accordpanelID].Lat],
                zoom: zoom,
                duration: flyToDuration,
            });

            map.current.getSource('markerPoints')?.setData(
                {
                    type: 'FeatureCollection',
                    features: regionalHospitalList.map((hospital, index) => {
                        return {

                            center: [hospital.Lng, hospital.Lat],
                            geometry: {
                                type: 'Point',
                                coordinates: [hospital.Lng, hospital.Lat]
                            },
                            properties: {
                                'text-content': hospital.Name,
                                'color': (index === accordpanelID) ? 'hsl(0, 96%, 54%)' : 'hsla(0, 0%, 100%, 0.5)',
                                'marker-type': (index === accordpanelID) ? 'hospitalSelected' : 'hospitalNormal',
                                'sort-order': (index === accordpanelID) ? 1 : 10

                            }
                        }
                    })

                }
            );



        } else if (regionalMode === 'hotel') {
            map.current.flyTo({
                center: [regionalHotelList[accordpanelID].Lng,
                regionalHotelList[accordpanelID].Lat],
                zoom: zoom,
                duration: flyToDuration,
            });

            map.current.getSource('markerPoints')?.setData(
                {
                    type: 'FeatureCollection',
                    features: regionalHotelList.map((hotel, index) => {
                        return {

                            center: [hotel.Lng, hotel.Lat],
                            geometry: {
                                type: 'Point',
                                coordinates: [hotel.Lng, hotel.Lat]
                            },
                            properties: {
                                'text-content': hotel.Name,
                                'color': (index === accordpanelID) ? 'hsl(206, 96%, 54%)' : 'hsla(0, 0%, 100%, 0.5)',
                                'marker-type': (index === accordpanelID) ? 'hotelSelected' : 'hotelNormal',
                                'sort-order': (index === accordpanelID) ? 1 : 10

                            }
                        }
                    })

                }
            );






        } else if (!regionalMode) {







            if ((accordpanelID >= 0) && closestHospitalData && hospMarkers.current[0].length !== 0 && // Protection Clause
                closestHospitalData[accordpanelID].Lng && (closestHospitalData[accordpanelID].Lng !== 0)) {

                setGeojsonCoordinates(closestHospitalData[accordpanelID].directionAPI?.routes[0]?.geometry?.coordinates)

                map.current.flyTo({
                    center: [closestHospitalData[accordpanelID].Lng,
                    closestHospitalData[accordpanelID].Lat],
                    zoom: zoom,
                    duration: flyToDuration,
                });



                if (popupHosp.current) {
                    popupHosp.current.remove();
                }

                let i = 0;
                for (i = 0; i < hospMarkers.current[1].length; i++) {
                    if (hospMarkers.current[1][i] == accordpanelID) { break }
                }

                popupHosp.current = new mapboxgl.Popup({
                    offset: 20, closeButton: false,
                    closeOnClick: false, className: "mappopupHosp"
                })
                    .setHTML(`<button id="popupButtonPanel-${accordpanelID}" class="popup-Button">
                    ${((accordpanelID !== null) && closestHospitalData) ? closestHospitalData[accordpanelID].Name : ''}
                    </button>`);

                hospMarkers.current[0][i].setPopup(popupHosp.current).addTo(map.current).togglePopup();
                document.getElementById(`popupButtonPanel-${accordpanelID}`).addEventListener('click', () => setOpenModal(accordpanelID))

            }

            else if (map.current && closestHospitalData) { // For panelID = -1 meaning on click hotel info
                map.current.flyTo({ // Fly to new marker
                    center: [lng, lat],
                    duration: 100,
                    duration: flyToDuration,

                })
            }
        }

    }, [accordpanelID]);

    useEffect(() => { // On Changing Chosen Hotel

        if (marker.current) { //Remove old hospital markers
            marker.current.remove();
        }
        if (selectedValue) { // Create Hotel Marker and Popup

            const popup = new mapboxgl.Popup({ offset: 20, closeButton: false, closeOnClick: false, className: "mappopupHotel" }).setHTML(`<h1>${selectedValue && selectedValue.Name ? selectedValue.Name : ''}</h1>`); //broken using useRef

            marker.current = new mapboxgl.Marker(createHotelMarker(-1)).setLngLat([lng, lat]).setPopup(popup).addTo(map.current);
            marker.current.togglePopup();

            if (closestHospitalData && closestHospitalData.length !== 0) {

                addMarkers((filterByRegion) ? closestHospitalData.filter((entry) => { return entry.regionFilter }) : closestHospitalData); //Add markers to hospitals

                setGeojsonCoordinates(
                    (filterByRegion) ?
                        closestHospitalData?.filter((entry) => { return entry.regionFilter })[0]?.directionAPI?.routes[0]?.geometry?.coordinates
                        :
                        closestHospitalData?.directionAPI?.routes[0]?.geometry?.coordinates
                )

                // setClosestHospitalData(distances.current); //Send the list over for Accordion on the main App
            }
        }

        if (map.current) {
            map.current.flyTo({ // Fly to new marker
                center: [lng, lat],
                zoom: zoom
            })

        }

    }, [selectedValue, closestHospitalData, filterByRegion]);

    const wipeRegionAreas = (regionData) => {
        regionData?.forEach((area) => {
            map.current.setPaintProperty(area.Region, 'fill-opacity', 0);
            map.current.setPaintProperty(`${area.Region}-border`, 'line-opacity', 0);
        })
    }

    const setRegionLayer = (currentRegion) => {
        map.current.setPaintProperty('routeLayerBackground', 'line-opacity', 0);
        map.current.setPaintProperty('routeLayerAnimated', 'line-opacity', 0);

        if (currentRegion === 'All') {
            regionDataQuery?.data?.data?.forEach((area) => {
                map.current.setPaintProperty(area.Region, 'fill-opacity', 0.025);
                map.current.setPaintProperty(`${area.Region}-border`, 'line-opacity', 1);
            })
        } else {
            wipeRegionAreas(regionDataQuery?.data?.data);
            map.current.setPaintProperty(currentRegion, 'fill-opacity', 0.05);
            map.current.setPaintProperty(`${currentRegion}-border`, 'line-opacity', 1);
        }
    };

    useEffect(() => {
        if (!map.current) { return }
        if (regionalMode === 'hospital' && regionalHospitalList && regionalHospitalList.length > 0 && selectedRegion) {
            // switched to regional mode or changed region within regional mode
            setRegionLayer(selectedRegion);

            const filteredList = regionalHospitalList?.filter((entry) => { return formats.includes(entry.Type) })

            wipeMarkers();
            // addMarkers(filteredList);

            map.current.getSource('markerPoints')?.setData(
                {
                    type: 'FeatureCollection',
                    features: regionalHospitalList.map((hospital) => {
                        return {

                            center: [hospital.Lng, hospital.Lat],
                            geometry: {
                                type: 'Point',
                                coordinates: [hospital.Lng, hospital.Lat]
                            },
                            properties: {
                                'text-content': hospital.Name,
                                'color': 'hsla(9, 93%, 37%, 0.67)',
                                'marker-type': 'hospitalNormal',
                                'sort-order': 1,

                            }
                        }
                    })

                }
            );




        } else if (regionalMode === 'hotel' && regionalHotelList && regionalHotelList.length > 0 && selectedRegion) {
            wipeMarkers();

            setRegionLayer(selectedRegion);


            if (map.current && regionalHotelList) {




                map.current.getSource('markerPoints')?.setData(
                    {
                        type: 'FeatureCollection',
                        features: regionalHotelList.map((hotel) => {
                            return {

                                center: [hotel.Lng, hotel.Lat],
                                geometry: {
                                    type: 'Point',
                                    coordinates: [hotel.Lng, hotel.Lat]
                                },
                                properties: {
                                    'text-content': hotel.Name,
                                    'color': 'hsl(206, 96%, 54%)',
                                    'marker-type': 'hotelNormal',
                                    'sort-order': 1,

                                }
                            }
                        })

                    }
                );
            }




        } else if (!regionalMode) {

            map.current.getSource('markerPoints')?.setData(
                {
                    type: 'FeatureCollection',
                    features: []
                }
            );
        }

    }, [regionalHospitalList, regionalHotelList, regionalMode, formats, selectedRegion])


    /*

        useEffect(() => {
            if (map.current && geocodeQuery?.data?.data) {


                map.current.getSource('points')?.setData(
                    {
                        ...geocodeQuery.data.data,
                        features: geocodeQuery.data.data.features.map((feature) => {
                            return {
                                ...feature,
                                properties: {
                                    ...feature.properties,
                                    'text-content': feature.text_tr,
                                    'color': 'hsla(0, 0%, 100%, 0.5)',
                                    'marker-type': 'normal',
                                    'sort-order': 1,

                                }
                            }
                        })

                    }
                );
            }


        }, [geocodeQuery.data]);
    */










    useEffect(() => {
        if (!!selectedValue && !regionalMode) { // switched to hotel search mode
            wipeRegionAreas(regionDataQuery?.data?.data);
            if (filterByRegion) {
                map.current.setPaintProperty(selectedValue?.Region, 'fill-opacity', 0.025);
                map.current.setPaintProperty(`${selectedValue?.Region}-border`, 'line-opacity', 0.5);
            }
            map.current.setPaintProperty('routeLayerBackground', 'line-opacity', 0.25);
            map.current.setPaintProperty('routeLayerAnimated', 'line-opacity', 1);
        }

    }, [selectedValue, regionalMode, filterByRegion])






    useEffect(() => {
        if (!regionalMode && closestHospitalData) {
            let filteredList = (filterByRegion) ? closestHospitalData.filter((entry) => { return entry.regionFilter }) : closestHospitalData
            filteredList = filteredList.filter((entry) => { return formats.includes(entry.Type) })
            addMarkers(filteredList); //Add markers to hospitals
        }
    }, [filterByRegion, formats])

    return (
        <div ref={mapContainer} style={{ width: '100%', height: '100%' }} />
    );
};


export default MapComponent;