
import { useReducer, useState } from 'react';

import {
    MenuItem, Typography


} from '@mui/material';
import { FormField } from '../../StyledComponents/FormField';
import { NewPatientButton } from '../../StyledComponents/NewPatientButton';
import TextLanguage from '../../data/TextLanguage.json';
import { useCreateUserMutation } from '../hooks/useCreateUserMutation';

import { userTypes } from '../../data/userTypes.jsx';

function reducer(state, action) {
    switch (action.type) {
        case 'SET_FIELD':
            return {
                ...state,
                [action.fieldName]: action.payload,
            };
        default:
            return state;
    }
}

const initialState = {
    "Name": '',
    "UserType": '',
};



export const UserCreateForm = ({ setsnackbarStatus, setModalOpen, lang }) => {

    const [userData, setUserData] = useState(null);
    const [isError, setIsError] = useState(false);

    const userCreateMutation = useCreateUserMutation({ setUserData, setsnackbarStatus, setIsError });

    const [hotelUpdater, dispatch] = useReducer(reducer, initialState);

    const handleFieldChange = (fieldName) => (e) => {

        dispatch({
            type: 'SET_FIELD',
            fieldName: fieldName,
            payload: e.target.value,
        });

    };

    const handleSubmitEdit = () => {


        userCreateMutation.mutate({
            updaterData: {
                label: hotelUpdater.Name,
                type: hotelUpdater.UserType
            }
        });

    }

    const userDataMap = {
        username: 'Username',
        password: 'Password',
    }


    return (<div className='HotelEditFormContainer'>

        <div style={{ display: 'flex', justifyContent: 'center', height: '100%', padding: '0px' }}>

            <div className='CreateEntryFormContainer' >
                <Typography variant='h6' sx={{ fontSize: '1.75rem', color: 'white', fontWeight: 'bolder', marginBottom: '0.75rem' }}>
                    {TextLanguage['NewUser'][lang]}
                </Typography>


                <div className='form-row'>

                    <FormField
                        id="select-type"
                        select
                        value={hotelUpdater.UserType}
                        onChange={handleFieldChange('UserType')}
                        defaultValue={'Client'}
                        helperText={TextLanguage['UserType'][lang]}
                    >
                        {userTypes.map((option) => (
                            <MenuItem key={`type-opt-${option}`} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </FormField>

                </div>


                <div className='form-row'>

                    {hotelUpdater.UserType === 'Client' && <FormField
                        label={TextLanguage['HospitalName'][lang]}
                        value={hotelUpdater.Name}
                        onChange={handleFieldChange('Name')} />}

                </div>

                <div className='form-row'>

                    {hotelUpdater.UserType === 'InsuranceCo' && <FormField
                        label={TextLanguage['InsuranceName'][lang]}
                        value={hotelUpdater.Name}
                        onChange={handleFieldChange('Name')} />}

                </div>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '0.2rem', width: '100%', padding: '2rem 4rem', background: '#26282c', borderRadius: 8 }}>

                    {userData && Object.keys(userData).length > 0 && Object.keys(userDataMap).map((key) => {
                        return (<div>

                            <Typography sx={{ fontSize: '1.2rem', color: 'white', fontWeight: 'bolder', marginBottom: '0.75rem' }}>
                                {`${userDataMap[key]}: ${userData[key]}`}
                            </Typography>


                        </div>)

                    })

                    }

                      {isError &&  <Typography sx={{ fontSize: '1.2rem', color: 'red', fontWeight: 'bolder', marginBottom: '0.75rem' }}>
                                {`Failed to Create Blob Container`}
                            </Typography>}

                </div>


                <div style={{ marginTop: '40px', marginBottom: '0px', alignItems: 'center' }}>
                    <NewPatientButton

                        sx={{
                            fontSize: '1rem', color: 'white', m: '5px', padding: '5px 15px 5px 15px', width: '150px', fontWeight: 'bold',
                            background: 'rgb(52, 59, 70)'
                        }}
                        onClick={handleSubmitEdit}>
                        Submit
                    </NewPatientButton>
                </div>

            </div>


        </div>

    </div>);
}

